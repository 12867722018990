import * as React from "react";
import translation from "../../language/TranslationProvider";

import { ReactComponent as CustomerSvg } from "../../icons/customer.svg";
import { ReactComponent as ProductSvg } from "../../icons/products.svg";
import { ReactComponent as FormulaSvg } from "../../icons/formula.svg";

export const ProgrammMenu = ({ isMenuOpen }) => {
  return (
    <div className={`app-menu ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="programm-title-div">
        <h2 className="programm-title">Programme</h2>
      </div>
      <div className="programm2-menu">
        <a className="programm2" href="/app/products/">
          <ProductSvg
            className="fill-white h-7 w-7"
            title="Products"
            alt="Products"
          />
          <p className="programm2-text">Products</p>
        </a>
        <a className="programm2" href="/app/customermanagement/">
          <CustomerSvg
            className="fill-white h-7 w-7"
            title="Kundenverwaltung"
            alt="Kundenverwaltung"
          />
          <p className="programm2-text">Kundenverwaltung</p>
        </a>
        <a className="programm2" href="/app/documents/">
          <FormulaSvg
            className="fill-white h-7 w-7"
            title="Dokumente"
            alt="Rechnungen"
          />
          <p className="programm2-text">Dokumente</p>
        </a>
      </div>
    </div>
  );
};
