import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, limit, getDocs } from "firebase/firestore";
import "./Login.css";

import { ReactComponent as LogoSvg } from "../../icons/logoWhiteFull.svg";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);

      // get user and save
      const userCollection = query(
        collection(db, "users"),
        where("email", "==", emailRef.current.value.toLowerCase()),
        limit(1)
      );
      const userData = await getDocs(userCollection);
      const userArray = userData.docs.map((userDoc) => ({ ...userDoc.data() }));
      localStorage.appUser = JSON.stringify(userArray[0]);

      // get company and save
      const companyCollection = query(
        collection(db, "company"),
        where("__name__", "==", userArray[0].companyId),
        limit(1)
      );
      const companyData = await getDocs(companyCollection);
      const companyArray = companyData.docs.map((companyDoc) => ({
        ...companyDoc.data(),
      }));
      localStorage.company = JSON.stringify(companyArray[0]);

      navigate("/app/");
    } catch {
      setError("Fehler beim Einloggen!");
    }
    setLoading(false);
  }

  return (
    <>
      <LogoSvg className="h-12 w-100 mt-32" alt="Halfwork" />
      <div className="container-login">
        <Card
          className="text-center"
          style={{ maxWidth: "28rem", maxHeight: "30rem" }}
        >
          <Card.Body>
            <h2 className="text-center mb-4">Log In</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit">
                Log In
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/app/forgot-password">Forgot Password?</Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2 text-white">
          Need an account? <Link to="/app/signup">Sign Up</Link>
        </div>
      </div>
    </>
  );
}
