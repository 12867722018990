import React, { useRef, useState, useEffect } from "react";
import "./NewProjectMenu.css";
import {
  query,
  where,
  getDocs,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast from "react-hot-toast";
import "react-dropdown/style.css";
import { ReactComponent as DeleteSvg } from "../../icons/delete.svg";
import BaseDataTable from "./../core/BaseDataTable";

import { createOfferPdf} from "../pdfLogic/OfferPdf";

const columns = [
  {
    name: translation("titleProductGroupName"),
    selector: (row) => row.productGroupName,
    sortable: true,
  },
  {
    name: translation("titlePositionCount"),
    selector: (row) => row.positions.length,
    sortable: true,
  }
];

const columnsProducts = [
  {
    name: translation("titleProductNumber"),
    selector: (row) => row.number,
    sortable: true,
  },
  {
    name: translation("titleProductName"),
    selector: (row) => row.productName,
    sortable: true,
  },
  {
    name: translation("titleProductCategory"),
    selector: (row) => row.productCategory,
    sortable: true,
  },
  {
    name: translation("titleProductPrice"),
    selector: (row) => row.price,
    sortable: true,
  },
];

export const NewProjectMenu = ({
  isNewProject,
  company,
  isNewProjectDataSet,
  invoiceId,
  current,
  customerName,
  address,
  customerId,
  closeFunction
}) => {
  const customerRef = useRef();
  const offerNoRef = useRef();
  const offerDateRef = useRef();
  const streetRef = useRef();
  const plzRef = useRef();
  const cityRef = useRef();
  const projectStreetRef = useRef();
  const projectPlzRef = useRef();
  const projectCityRef = useRef();

  const [selectedProductGroupsRow, setSelectedProductGroupsRow] = useState("");
  const [selectedProductRow, setSelectedProductRow] = useState("");
  const [currentCustomerId, setCustomerId] = useState();
  const [settings, setSettings] = useState([]);

  const [positionFields, setPositionFields] = useState([
    {
      name: "",
      description: "",
      quantity: "",
      price: "",
    },
  ]);
  const [filteredProductGroups, setFilteredProductGroups] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const getAllSettings = async () => {
      const settingsCollection = query(
        collection(db, "settings"),
        where("__name__", "==", company)
      );
      const data = await getDocs(settingsCollection);
      setSettings(
        data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
      );
    };
    getAllSettings();

    const getProductGroups = async () => {
        const productGroupCollection = query(
          collection(db, "productGroups"),
          where("companyId", "==", company)
        );
        const data = await getDocs(productGroupCollection);
        let productGroupList = data.docs.map((productGroupDoc) => ({
          ...productGroupDoc.data(),
          id: productGroupDoc.id,
        }));
        setFilteredProductGroups(productGroupList);
    };
    getProductGroups();

    const getProducts = async () => {
      const productsCollection = query(
        collection(db, "products"),
        where("companyId", "==", company),
        where("dataState", "!=", 0)
      );
      const data = await getDocs(productsCollection);
      let productsList = data.docs.map((productsDoc) => ({
        ...productsDoc.data(),
        id: productsDoc.id,
      }));
      setFilteredProducts(productsList);
    };
    getProducts();
  }, [company, closeFunction]);

  async function create(e) {
    e.preventDefault();

    try {
      const invoiceCollectionRef = doc(db, "customers", customerId);

      await updateDoc(invoiceCollectionRef, {
        projects: arrayUnion({
          customer: customerRef.current.value,
          street: streetRef.current.value,
          plz: plzRef.current.value,
          city: cityRef.current.value,
          projectStreet: projectStreetRef.current.value,
          projectPlz: projectPlzRef.current.value,
          projectCity: projectCityRef.current.value,
          positions: positionFields,
          offerNo: offerNoRef.current.value,
          offerDate: offerDateRef.current.value,
          newDate: Date.now(),
          editDate: Date.now(),
          dataState: 1,
        })
      });
      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  async function update(e) {
    e.preventDefault();

    try {
      const invoiceCollectionRef = doc(db, "customers", customerId);

      await updateDoc(invoiceCollectionRef, {
        projects: arrayUnion({
          customer: customerRef.current.value,
          street: streetRef.current.value,
          plz: plzRef.current.value,
          city: cityRef.current.value,
          projectStreet: projectStreetRef.current.value,
          projectPlz: projectPlzRef.current.value,
          projectCity: projectCityRef.current.value,
          offerNo: offerNoRef.current.value,
          offerDate: offerDateRef.current.value,
          positions: positionFields,
          editDate: new Date(),
        })
      });

      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function getValue(column) {
    let inputFieldsSet = false;

     if (
        column === "customer" &&
        current["positions"] !== null &&
        current["positions"] !== undefined &&
        current["positions"].length > 0 &&
        customerId !== currentCustomerId
      ) {
        //set input fields from database
        inputFieldsSet = true;
        setPositionFields(current["positions"]);
      } else if (
        column === "customer" &&
        customerId !== currentCustomerId &&
        inputFieldsSet === false
      ) {
        //set input fields from default
        setPositionFields([]);
      }

      if (customerId !== currentCustomerId) {
        setCustomerId(customerId);
      }

    if(column === 'customer' && (customerName !== null && customerName !== undefined)) {
      return customerName;
    }
    if((column === 'street' || column === 'projectStreet') && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressStreet"] + " " + address[0]["addressStreet2"];
    }
    if((column === 'city' || column === 'projectCity')  && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressCity"];
    }
    if((column === 'plz' || column === 'projectPlz')  && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressZipcode"];
    }
    if (current !== null && current !== undefined && current.length > 0) {
      try {
        console.log(current);
        return current[0][column];
      } catch {}
    }
    return "";
  }

  const handleFormChange = (index, event) => {
    let data = [...positionFields];
    data[index][event.target.name] = event.target.value;
    setPositionFields(data);
  };

  const addPositionFieldsByGroup = (e) => {

    let newfields = [];
    for (let i = 0; i < selectedProductGroupsRow.positions.length; i++) {

      let position = selectedProductGroupsRow.positions[i];
      let product = loadProduct(position.positionId);

      let newfield = {
        name: product.productName,
        description: product.productDescriptionText,
        quantity: position.positionQuantity,
        price: product.price,
      };
  
      newfields[i] = newfield;
    }
    
    setPositionFields([...positionFields, ...newfields]);
  };

  function loadProduct(positionId) {
      return filteredProducts.filter((product) => product.id === positionId)[0];
   }

  const addPositionFieldsByProduct = (e) => {
    console.log(selectedProductRow);

    let newfield = {
      name: selectedProductRow.productName,
      description: selectedProductRow.productDescriptionText,
      quantity: 1,
      price: selectedProductRow.price,
    };

    setPositionFields([...positionFields, newfield]);
  };

  const removePositionFields = (e, index) => {
    e.preventDefault();

    let data = [...positionFields];
    data.splice(index, 1);
    setPositionFields(data);
  };

  return (
    <div className={`project-menu ${isNewProject ? "project-open" : ""}`}>
      
      <form
        className="settingForm"
        onSubmit={isNewProjectDataSet ? create : update}
      >
        <h2 className="settingsSectionTitle">{translation("documentAddress")} </h2>
        <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleCustomer")}</label>
                    <input
                        className="settingInput"
                        ref={customerRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("customer")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleStreet")}</label>
                    <input
                        className="settingInput"
                        ref={streetRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("street")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titlePlz")}</label>
                    <input
                        className="settingInput"
                        ref={plzRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("plz")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleCity")}</label>
                    <input
                        className="settingInput"
                        ref={cityRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("city")}
                    />
            </div>
        </div>

        <h2 className="settingsSectionTitle">{translation("projectAddress")} </h2>
        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleStreet")}</label>
                    <input
                        className="settingInput"
                        ref={projectStreetRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("projectStreet")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titlePlz")}</label>
                    <input
                        className="settingInput"
                        ref={projectPlzRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("projectPlz")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleCity")}</label>
                    <input
                        className="settingInput"
                        ref={projectCityRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("projectCity")}
                    />
            </div>
        </div>

        {/* POSITIONEN */}
        <h2 className="settingsSectionTitle">{translation("Positionen")} </h2>
        {positionFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <button
                  className="remove-button"
                  onClick={(e) => removePositionFields(e, index)}
                >
                  <DeleteSvg
                    title={translation("RemoveAddressToolTip")}
                  ></DeleteSvg>
                </button>
                <div className="settingsSmallInputFieldGroup2">
                  <label className="settinglabelSmall">
                    {translation("titleName")}
                  </label>
                  <input
                    name="name"
                    className="settingInputSmall"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.name}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titleDescription")}
                  </label>
                  <input
                    name="description"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.description}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup3">
                  <label className="settinglabelSmall">
                    {translation("titleQuantity")}
                  </label>
                  <input
                    name="quantity"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.quantity}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup3">
                  <label className="settinglabelSmall">
                    {translation("titlePrice")}
                  </label>
                  <input
                    name="price"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.price}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="mt-2"/>

        <BaseDataTable
          columns={columns}
          data={filteredProductGroups}
          onSelect={setSelectedProductGroupsRow}
          currentRow={selectedProductGroupsRow}
          rowDoubleClicked={addPositionFieldsByGroup}  
        />

        <div className="mt-2"/>

        <BaseDataTable
          columns={columnsProducts}
          data={filteredProducts}
          onSelect={setSelectedProductRow}
          currentRow={selectedProductRow}
          rowDoubleClicked={addPositionFieldsByProduct}  
        />

        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleOfferNo")}</label>
                    <input
                        className="settingInput"
                        ref={offerNoRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("offerNo")}
                    />
                    <label className="settinglabel">{translation("titleOfferDate")}</label>
                   <input
                        className="settingInput"
                        ref={offerDateRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("offerDate")}
                    />
            </div> 
            <div 
              className="printer w-30" 
              onClick={() => createOfferPdf(settings, current)}
            >
            {"Angebot drucken"}
          </div>
        </div>    

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewProjectDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
