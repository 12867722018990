import React, { useRef, useState } from "react";
import "./NewProductMenu.css";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast from "react-hot-toast";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

export const NewProductMenu = ({
  isNewProduct,
  company,
  isNewProductDataSet,
  productId,
  current,
  settings,
  closeFunction
}) => {
  const productNumberRef = useRef();
  const productNameRef = useRef();
  const stockKeepingUnitRef = useRef();
  const productCategoryRef = useRef();
  const lengthRef = useRef();
  const widthRef = useRef();
  const thicknessRef = useRef();
  const weightRef = useRef();
  const weightUnitRef = useRef();
  const colorRef = useRef();
  const priceRef = useRef();
  const pricePerRef = useRef();
  const priceUnitRef = useRef();
  const productDescriptionTextRef = useRef();

  const [currentProductId, setProductId] = useState();
  const [additionalProperties, setAdditionalProperties] = useState([
    {
      title: translation("Information"),
      code: "",
      value: translation("AdditionalPropertiesCanBeDefinedInTheSettings"),
    },
  ]);

  const priceUnits = ["pce", "kg", "m2", "m3"];

  async function create(e) {
    e.preventDefault();

    try {
      await addDoc(collection(db, "products"), {
        companyId: company,

        number: productNumberRef.current.value,
        productName: productNameRef.current.value,
        stockKeepingUnit: stockKeepingUnitRef.current.value,
        productCategory: productCategoryRef.current.value,
        length: lengthRef.current.value,
        width: widthRef.current.value,
        thickness: thicknessRef.current.value,
        weigth: weightRef.current.value,
        weigthUnit: weightUnitRef.current.value,
        color: colorRef.current.value,
        price: priceRef.current.value,
        pricePer: pricePerRef.current.value,
        priceUnit: priceUnitRef.current.dropdownRef.current.textContent,
        productDescriptionText: productDescriptionTextRef.current.value,
        dataState: 1,

        additionalProperties: additionalProperties,

        newDate: Date.now(),
        editDate: Date.now(),
      });
      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "products", productId), {
        number: productNumberRef.current.value,
        productName: productNameRef.current.value,
        stockKeepingUnit: stockKeepingUnitRef.current.value,
        productCategory: productCategoryRef.current.value,
        length: lengthRef.current.value,
        width: widthRef.current.value,
        thickness: thicknessRef.current.value,
        weigth: weightRef.current.value,
        weigthUnit: weightUnitRef.current.value,
        color: colorRef.current.value,
        price: priceRef.current.value,
        pricePer: pricePerRef.current.value,
        priceUnit: priceUnitRef.current.dropdownRef.current.textContent,
        productDescriptionText: productDescriptionTextRef.current.value,

        additionalProperties: additionalProperties,

        editDate: Date.now(),
      });

      window.location.reload();
      toast.success(translation("saveSuccess"));
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function initAdditionalProperties(currentProperties) {
    let additionalPropertiesDefault;
    if (settings !== null && settings !== undefined && settings.length > 0) {
      additionalPropertiesDefault = settings[0].product["additionalProperties"];
    }

    if (
      additionalPropertiesDefault !== null &&
      additionalPropertiesDefault !== undefined &&
      additionalPropertiesDefault.length > 0
    ) {
      if (
        currentProperties !== null &&
        currentProperties !== undefined &&
        currentProperties.length > 0
      ) {
        for (let i = 0; i < additionalPropertiesDefault.length; i++) {
          let code = additionalPropertiesDefault[i]["code"];
          let toBeAdded = true;
          for (let z = 0; z < currentProperties.length; z++) {
            if (currentProperties[z]["code"] === code) {
              toBeAdded = false;
              break;
            } else if (currentProperties[z]["code"] === "") {
              currentProperties.splice(z, 1);
            }
          }
          if (toBeAdded) {
            currentProperties.push(additionalPropertiesDefault[i]);
          }
        }
        setAdditionalProperties(currentProperties);
      } else {
        setAdditionalProperties(additionalPropertiesDefault);
      }
    } else if (
      currentProperties !== null &&
      currentProperties !== undefined &&
      currentProperties.length > 0
    ) {
      setAdditionalProperties(currentProperties);
    } else {
      setAdditionalProperties([
        {
          title: translation("Information"),
          code: "",
          value: translation("AdditionalPropertiesCanBeDefinedInTheSettings"),
        },
      ]);
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {
      //this is the first column
      let additionalPropertiesSet = false;

      if (
        column === "number" &&
        current[0]["additionalProperties"] !== null &&
        current[0]["additionalProperties"] !== undefined &&
        current[0]["additionalProperties"].length > 0 &&
        productId !== currentProductId
      ) {
        //set input fields from database
        additionalPropertiesSet = true;
        initAdditionalProperties(current[0]["additionalProperties"]);
      } else if (
        column === "number" &&
        productId !== currentProductId &&
        additionalPropertiesSet === false
      ) {
        //set input fields from default
        initAdditionalProperties(null);
      }

      if (productId !== currentProductId) {
        setProductId(productId);
      }

      try {
        if (
          column === "priceUnit" &&
          (current[0][column] === null ||
            current[0][column] === undefined ||
            "" === current[0][column])
        ) {
          return priceUnits[0];
        }
        return current[0][column];
      } catch {}
    } else {
      if (column === "number" && productId !== currentProductId) {
        setProductId(productId);
        initAdditionalProperties(null);
      }
    }
    if (column === "priceUnit") {
      return priceUnits[0];
    }
    return "";
  }

  const handleFormChangeAdditionalProperties = (index, event) => {
    let data = [...additionalProperties];
    data[index][event.target.name] = event.target.value;
    setAdditionalProperties(data);
  };

  return (
    <div className={`product-menu ${isNewProduct ? "product-open" : ""}`}>
       <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
      <form
        className="settingForm"
        onSubmit={isNewProductDataSet ? create : update}
      >
        {/* PRODUCT */}
        <h2 className="settingsSectionTitle">{translation("Product")} </h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleProductNumber")}
            </label>
            <input
              className="settingInput"
              ref={productNumberRef}
              required
              autoComplete="off"
              defaultValue={getValue("number")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleProductName")}
            </label>
            <input
              className="settingInput"
              ref={productNameRef}
              autoComplete="off"
              defaultValue={getValue("productName")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleStockKeepingUnit")}
            </label>
            <input
              className="settingInput"
              ref={stockKeepingUnitRef}
              autoComplete="off"
              defaultValue={getValue("stockKeepingUnit")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleProductCategory")}
            </label>
            <input
              className="settingInput"
              ref={productCategoryRef}
              autoComplete="off"
              defaultValue={getValue("productCategory")}
            />
          </div>
        </div>

        {/* Product Informations */}
        <h2 className="settingsSectionTitle">
          {translation("ProductInformations")}
        </h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titleLength")}</label>
            <input
              className="settingInput"
              ref={lengthRef}
              type="number"
              autoComplete="off"
              defaultValue={getValue("length")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titleWidth")}</label>
            <input
              className="settingInput"
              ref={widthRef}
              type="number"
              autoComplete="off"
              defaultValue={getValue("width")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleThickness")}
            </label>
            <input
              className="settingInput"
              ref={thicknessRef}
              type="number"
              autoComplete="off"
              defaultValue={getValue("thickness")}
            />
          </div>
        </div>

        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titleWeigth")}</label>
            <input
              className="settingInput"
              ref={weightRef}
              type="number"
              autoComplete="off"
              defaultValue={getValue("weight")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleWeightUnit")}
            </label>
            <input
              className="settingInput"
              ref={weightUnitRef}
              autoComplete="off"
              defaultValue={getValue("weightUnit")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titleColor")}</label>
            <input
              className="settingInput"
              ref={colorRef}
              autoComplete="off"
              defaultValue={getValue("color")}
            />
          </div>
        </div>

        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titlePrice")}</label>
            <input
              className="settingInput"
              ref={priceRef}
              autoComplete="off"
              type="number"
              defaultValue={getValue("price")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titlePer")}</label>
            <input
              className="settingInput"
              ref={pricePerRef}
              autoComplete="off"
              type="number"
              defaultValue={getValue("pricePer")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titlePriceUnit")}
            </label>
            <Dropdown
              className="settingComboBox"
              ref={priceUnitRef}
              options={priceUnits}
              value={getValue("priceUnit")}
            />
          </div>
        </div>

        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleProductDescriptionText")}
            </label>
            <BaseTextareaAutosize 
              className="settingInput" 
              ref={productDescriptionTextRef}
              defaultValue={getValue("productDescriptionText")}
            />
          </div>
        </div>

        {/* ADDITIONAL PROPERTIES */}
        <h2 className="settingsSectionTitle">
          {translation("AdditionalProperties")}
        </h2>
        {additionalProperties.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">{input.title}</label>
                  <input
                    name="value"
                    className={
                      "" === input.code
                        ? "settingInputDisabled"
                        : "settingInput"
                    }
                    autoComplete="off"
                    disabled={"" === input.code}
                    onChange={(event) =>
                      handleFormChangeAdditionalProperties(index, event)
                    }
                    value={input.value}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewProductDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
