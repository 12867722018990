import React, { useRef, useState } from "react";
import "./NewCustomerMenu.css";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast from "react-hot-toast";
import { ReactComponent as DeleteSvg } from "../../icons/delete.svg";
import { ReactDimmer } from "react-dimmer";
import { NewProjectMenu } from "./NewProjectMenu.js";
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { styled } from '@mui/system';
import { buttonClasses } from '@mui/base/Button';
import BaseDataTable from "./../core/BaseDataTable";
import { ReactComponent as NewSvg } from "../../icons/new.svg";
import { ReactComponent as OpenSvg } from "../../icons/open.svg";

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(BaseTab)`
  color: #fff;
  cursor: pointer;
  font-size: 0.675rem;
  font-weight: 600;
  background-color: transparent;
  width: fit-content;
  padding: 2px 6px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  background-color: ${'#263859'};
  border-radius: 8px;
  margin-bottom: 2px;
  display: flex;
  width: fit-content;
  `,
);

const columns = [
  {
    name: translation("titleProjectStreet"),
    selector: (row) => row.projectStreet,
    sortable: true,
  },
  {
    name: translation("titleProjectPlz"),
    selector: (row) => row.projectPlz,
    sortable: true,
  },
  {
    name: translation("titleProjectCity"),
    selector: (row) => row.projectCity,
    sortable: true,
  }
];

export const NewCustomerMenu = ({
  isNewCustomer,
  company,
  isNewCustomerDataSet,
  customerId,
  current,
  settings,
  closeFunction
}) => {
  const customerNameRef = useRef();
  const customerName2Ref = useRef();
  const customerReferenceRef = useRef();
  const customerGroupRef = useRef();
  const contactNameRef = useRef();
  const contactPhoneRef = useRef();
  const ContactEmailRef = useRef();
  const ContactWebsiteRef = useRef();
  const CommentRef = useRef();
  const VatNumberRef = useRef();

  const [currentCustomerId, setCustomerId] = useState();
  const [selectedProjectRow, setSelectedProjectRow] = useState("");
  const [isNewProject, setNewProject] = useState(false);
  const [isNewProjectDataSet, setNewProjectDataSet] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      addressStreet: "",
      addressStreet2: "",
      addressZipcode: "",
      addressCity: "",
      addressState: "",
    },
  ]);
  const [additionalProperties, setAdditionalProperties] = useState([
    {
      title: translation("Information"),
      code: "",
      value: translation("AdditionalPropertiesCanBeDefinedInTheSettings"),
    },
  ]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  async function create(e) {
    e.preventDefault();

    try {
      await addDoc(collection(db, "customers"), {
        companyId: company,
        name: customerNameRef.current.value,
        name2: customerName2Ref.current.value,
        reference: customerReferenceRef.current.value,
        contactName: contactNameRef.current.value,
        contactPhone: contactPhoneRef.current.value,
        contactEmail: ContactEmailRef.current.value,
        contactWebsite: ContactWebsiteRef.current.value,
        vatNumber: VatNumberRef.current.value,
        comment: CommentRef.current.value,
        customerGroup: customerGroupRef.current.value,
        addresses: inputFields,
        additionalProperties: additionalProperties,
        newDate: Date.now(),
        editDate: Date.now(),
        dataState: 1,
      });
      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "customers", customerId), {
        name: customerNameRef.current.value,
        name2: customerName2Ref.current.value,
        reference: customerReferenceRef.current.value,
        contactName: contactNameRef.current.value,
        contactPhone: contactPhoneRef.current.value,
        contactEmail: ContactEmailRef.current.value,
        contactWebsite: ContactWebsiteRef.current.value,
        vatNumber: VatNumberRef.current.value,
        comment: CommentRef.current.value,
        addresses: inputFields,
        additionalProperties: additionalProperties,
        customerGroup: customerGroupRef.current.value,
        editDate: Date.now(),
      });

      window.location.reload();
      toast.success(translation("saveSuccess"));
    } catch (error) {
      toast.error(translation("saveError"));
      console.error("Update Error: " + error);
    }
  }

  function initAdditionalProperties(currentProperties) {
    let additionalPropertiesDefault;
    if (settings !== null && settings !== undefined && settings.length > 0) {
      additionalPropertiesDefault =
        settings[0].customer["additionalProperties"];
    }

    if (
      additionalPropertiesDefault !== null &&
      additionalPropertiesDefault !== undefined &&
      additionalPropertiesDefault.length > 0
    ) {
      if (
        currentProperties !== null &&
        currentProperties !== undefined &&
        currentProperties.length > 0
      ) {
        for (let i = 0; i < additionalPropertiesDefault.length; i++) {
          let code = additionalPropertiesDefault[i]["code"];
          let toBeAdded = true;
          for (let z = 0; z < currentProperties.length; z++) {
            if (currentProperties[z]["code"] === code) {
              toBeAdded = false;
              break;
            } else if (currentProperties[z]["code"] === "") {
              currentProperties.splice(z, 1);
            }
          }
          if (toBeAdded) {
            currentProperties.push(additionalPropertiesDefault[i]);
          }
        }
        setAdditionalProperties(currentProperties);
      } else {
        setAdditionalProperties(additionalPropertiesDefault);
      }
    } else if (
      currentProperties !== null &&
      currentProperties !== undefined &&
      currentProperties.length > 0
    ) {
      setAdditionalProperties(currentProperties);
    } else {
      setAdditionalProperties([
        {
          title: translation("Information"),
          code: "",
          value: translation("AdditionalPropertiesCanBeDefinedInTheSettings"),
        },
      ]);
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {
      // this is the first column
      let inputFieldsSet = false;
      let additionalPropertiesSet = false;
      let projectFieldsSet = false;
      if (
        column === "name" &&
        current[0]["addresses"] !== null &&
        current[0]["addresses"] !== undefined &&
        current[0]["addresses"].length > 0 &&
        customerId !== currentCustomerId
      ) {
        //set input fields from database
        inputFieldsSet = true;
        setInputFields(current[0]["addresses"]);
      } else if (
        column === "name" &&
        customerId !== currentCustomerId &&
        inputFieldsSet === false
      ) {
        //set input fields from default
        setInputFields([
          {
            addressStreet: "",
            addressStreet2: "",
            addressZipcode: "",
            addressCity: "",
            addressState: "",
          },
        ]);
      }

      if (
        column === "name" &&
        current[0]["projects"] !== null &&
        current[0]["projects"] !== undefined &&
        current[0]["projects"].length > 0 &&
        customerId !== currentCustomerId
      ) {
        //set project fields from database
        projectFieldsSet = true;
        setFilteredProjects(current[0]["projects"]);
      } else if (
        column === "name" &&
        customerId !== currentCustomerId &&
        projectFieldsSet === false
      ) {
        //set input fields from default
        setFilteredProjects([]);
      }

      if (
        column === "name" &&
        current[0]["additionalProperties"] !== null &&
        current[0]["additionalProperties"] !== undefined &&
        current[0]["additionalProperties"].length > 0 &&
        customerId !== currentCustomerId
      ) {
        //set input fields from database
        additionalPropertiesSet = true;
        initAdditionalProperties(current[0]["additionalProperties"]);
      } else if (
        column === "name" &&
        customerId !== currentCustomerId &&
        additionalPropertiesSet === false
      ) {
        //set input fields from default
        initAdditionalProperties(null);
      }

      if (customerId !== currentCustomerId) {
        setCustomerId(customerId);
      }

      if (column !== "logo") {
        try {
          return current[0][column];
        } catch {}
      }
    } else {
      if (column === "name" && customerId !== currentCustomerId) {
        setCustomerId(customerId);
        setInputFields([
          {
            addressStreet: "",
            addressStreet2: "",
            addressZipcode: "",
            addressCity: "",
            addressState: "",
          },
        ]);
        initAdditionalProperties(null);
      }
    }
    return "";
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const handleFormChangeAdditionalProperties = (index, event) => {
    let data = [...additionalProperties];
    data[index][event.target.name] = event.target.value;
    setAdditionalProperties(data);
  };

  const addAddressFields = (e) => {
    e.preventDefault();

    let newfield = {
      addressStreet: "",
      addressStreet2: "",
      addressZipcode: "",
      addressCity: "",
      addressState: "",
    };

    setInputFields([...inputFields, newfield]);
  };

  const removeAddressFields = (e, index) => {
    e.preventDefault();

    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  function openProjectCreation(isNewDataSet) {
    setNewProjectDataSet(isNewDataSet);
    setNewProject(true);
  };

  return (
    <>
    <div className={`customer-menu ${isNewCustomer ? "customer-open" : ""}`}>
    <button className="closeForm mt-10" onClick={() => closeFunction(false)}>X</button>
      <Tabs defaultValue={isNewCustomerDataSet ? 0 : 1}>
        <TabsList>
          <Tab value={0}>{translation("titleCustomerInformationTab")}</Tab>
          <Tab value={1}>{translation("titleProjectsTab")}</Tab>
        </TabsList>
        <BaseTabPanel value={0}>
      <form
        className="settingForm"
        onSubmit={isNewCustomerDataSet ? create : update}
      >
        
        {/* CUSTOMER */}
        <h2 className="settingsSectionTitle">{translation("Customer")} </h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
          <label className="settinglabel">{translation("titleName")}</label>
            <input
              className="settingInput"
              ref={customerNameRef}
              autoComplete="off"
              defaultValue={getValue("name")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">{translation("titleName2")}</label>
            <input
              className="settingInput"
              ref={customerName2Ref}
              autoComplete="off"
              defaultValue={getValue("name2")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleCustomerReference")}
            </label>
            <input
              className="settingInput"
              ref={customerReferenceRef}
              autoComplete="off"
              defaultValue={getValue("reference")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleCustomerGroup")}
            </label>
            <input
              className="settingInput"
              ref={customerGroupRef}
              autoComplete="off"
              defaultValue={getValue("customerGroup")}
            />
          </div>
        </div>

        {/* ADDRESS */}
        <h2 className="settingsSectionTitle">{translation("Address")} </h2>
        {inputFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <button
                  className="remove-button"
                  onClick={(e) => removeAddressFields(e, index)}
                >
                  <DeleteSvg
                    title={translation("RemoveAddressToolTip")}
                  ></DeleteSvg>
                </button>
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">
                    {translation("titleAddressStreet")}
                  </label>
                  <input
                    name="addressStreet"
                    className="settingInput"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressStreet}
                  />
                </div>
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">
                    {translation("titleAddressStreet2")}
                  </label>
                  <input
                    name="addressStreet2"
                    className="settingInput"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressStreet2}
                  />
                </div>
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">
                    {translation("titleAddressZipcode")}
                  </label>
                  <input
                    name="addressZipcode"
                    className="settingInput"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressZipcode}
                  />
                </div>
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">
                    {translation("titleAddressCity")}
                  </label>
                  <input
                    name="addressCity"
                    className="settingInput"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressCity}
                  />
                </div>
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">
                    {translation("titleAddressState")}
                  </label>
                  <input
                    name="addressState"
                    className="settingInput"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressState}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <button
          className="add-button"
          onClick={addAddressFields}
          title={translation("AddAddressToolTip")}
        >
          +
        </button>

        {/* CONTACT */}
        <h2 className="settingsSectionTitle">{translation("Contact")}</h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleCustomerContact")}
            </label>
            <input
              className="settingInput"
              ref={contactNameRef}
              autoComplete="off"
              defaultValue={getValue("contactName")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleContactPhone")}
            </label>
            <input
              className="settingInput"
              ref={contactPhoneRef}
              autoComplete="off"
              defaultValue={getValue("contactPhone")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleContactEmail")}
            </label>
            <input
              className="settingInput"
              ref={ContactEmailRef}
              autoComplete="off"
              defaultValue={getValue("contactEmail")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleContactWebsite")}
            </label>
            <input
              className="settingInput"
              ref={ContactWebsiteRef}
              autoComplete="off"
              defaultValue={getValue("contactWebsite")}
            />
          </div>
        </div>

        {/* ADDITIONALI INFORMATION */}
        <h2 className="settingsSectionTitle">
          {translation("AdditionalInformation")}
        </h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup25">
            <label className="settinglabel">
              {translation("titleVatNumber")}
            </label>
            <input
              className="settingInput"
              ref={VatNumberRef}
              autoComplete="off"
              defaultValue={getValue("vatNumber")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleComment")}
            </label>
            <input
              className="settingInput"
              ref={CommentRef}
              autoComplete="off"
              defaultValue={getValue("comment")}
            />
          </div>
        </div>

        {/* ADDITIONAL PROPERTIES */}
        <h2 className="settingsSectionTitle">
          {translation("AdditionalProperties")}
        </h2>
        {additionalProperties.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <div className="settingsInputFieldGroup">
                  <label className="settinglabel">{input.title}</label>
                  <input
                    name="value"
                    className={
                      "" === input.code
                        ? "settingInputDisabled"
                        : "settingInput"
                    }
                    autoComplete="off"
                    disabled={"" === input.code}
                    onChange={(event) =>
                      handleFormChangeAdditionalProperties(index, event)
                    }
                    value={input.value}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <div className="settingsInputGroup">

          <div className="settingsInputFieldGroup">
            <input
              className="settingSubmit"
              type="submit"
              value={translation(isNewCustomerDataSet ? "create" : "update")}
            />
          </div>
        </div>
      </form>
      </BaseTabPanel>
        <BaseTabPanel value={1}>
        <div
          className="settingForm"
        >
          <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
          <div className="buttons">
          <input
          //just for format of other buttons
          id="filter"
          className="filter invisible"
          //onChange={changeFilter}
          type="text"
          placeholder={translation("search")}
          aria-label="Search Input"
          />
            <button 
              className="open" 
              onClick={() => openProjectCreation(false)}
              >
              {" "}
              <OpenSvg title={translation("open")} alt="Open" />{" "}
            </button>
            <button 
              className="new" 
              onClick={() => openProjectCreation(true)}
              >
              {" "}
              <NewSvg title={translation("new")} alt="New" />{" "}
            </button>
          </div>
          <BaseDataTable
            columns={columns}
            data={filteredProjects}
            onSelect={setSelectedProjectRow}
            currentRow={selectedProjectRow}
            rowDoubleClicked={openProjectCreation}  
          />
          </div>
          </div>
        </div>
        </BaseTabPanel>
        </Tabs>

      <ReactDimmer
        isOpen={isNewProject}
        exitDimmer={setNewProject}
        zIndex={104}
        blur={1.5}
      />

      {isNewProject === true && (
        <NewProjectMenu
          key={null}
          isNewProject={isNewProject}
          company={company}
          current={selectedProjectRow}
          isNewProjectDataSet={isNewProjectDataSet}
          invoiceId={null}
          customerName={current[0]["name"] + " " + current[0]["name2"]}
          address={current[0]["addresses"]}
          customerId={current[0]["id"]}
          closeFunction={setNewProject}
        />
      )}
    </div>
    </>
  );
};
