import React, { useRef } from "react";
import "./FormMenu.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast, { Toaster } from "react-hot-toast";

export const DocumentSettingsMenu = ({ isDocumentMenu, company, current, closeFunction }) => {
  const companyNameRef = useRef();
  const companyCityRef = useRef();
  const companyStreetRef = useRef();

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "settings", company), {
        document: {
          name: companyNameRef.current.value,
          street: companyStreetRef.current.value,
          city: companyCityRef.current.value,
        },
      });
      toast.success(translation("saveSuccess"));
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {
      try {
        return current[0].document[column];
      } catch {}
    }
    return "";
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className={`invoice-menu ${isDocumentMenu ? "invoice-open" : ""}`}>
        <form className="settingForm" onSubmit={update}>
          <h1 className="settingsTitle">{translation("documentsSettings")}</h1>
          <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
          <label className="settinglabel">{translation("companyName")}</label>
          <input
            className="settingInput"
            ref={companyNameRef}
            autoComplete="off"
            defaultValue={getValue("name")}
          />
          <label className="settinglabel">{translation("companyStreet")}</label>
          <input
            className="settingInput"
            ref={companyStreetRef}
            autoComplete="off"
            defaultValue={getValue("street")}
          />
          <label className="settinglabel">{translation("companyCity")}</label>
          <input
            className="settingInput"
            ref={companyCityRef}
            autoComplete="off"
            defaultValue={getValue("city")}
          />
          <input
            className="settingSubmit"
            type="submit"
            value={translation("save")}
          />
        </form>
      </div>
    </>
  );
};
