import React, { useEffect, useState } from "react";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./SettingsMenu.css";
import translation from "../language/TranslationProvider";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { ReactDimmer } from "react-dimmer";

import { ReactComponent as CustomerSvg } from "../icons/customer.svg";
import { ReactComponent as ProductSvg } from "../icons/products.svg";
import { ReactComponent as FormulaSvg } from "../icons/formula.svg";
import { ReactComponent as CompanySvg } from "../icons/company.svg";
import { ReactComponent as PdfDesignerSvg } from "../icons/company.svg";
import { CompanySettingsMenu } from "./forms/CompanySettingsMenu";
import { CustomerSettingsMenu } from "./forms/CustomerSettingsMenu";
import { DocumentSettingsMenu } from "./forms/DocumentSettingsMenu";
import { ProductSettingsMenu } from "./forms/ProductsSettingsMenu";
import { PdfDesigner } from "./forms/PdfDesigner";

export const SettingsMenu = () => {
  const [isCompanyMenu, setCompanyMenu] = useState(false);
  const [isCustomerMenu, setCustomerMenu] = useState(false);
  const [isProductMenu, setProductMenu] = useState(false);
  const [isDocumentMenu, setDocumentMenu] = useState(false);
  const [isPdfDesigner, setPdfDesigner] = useState(false);
  const { currentUser } = useAuth();
  const [settingsCol, setSettings] = useState([]);
  const [addColumns, setAddColumns] = useState([
    {
      name: "customerdata",
      childs: [{
        name: "customername",
      }],
    },
    {
      name: "productdata",
      childs: [{
        name: "productname",
      }],
    },
    {
      name: "documentdata",
      childs: [{
        name: "invoiceno",
      }],
    },
    {
      name: "companydata",
      childs: [{
        name: "companyname",
      }],
    },
  ]);
  const [company, setCompany] = useState("");

  const openCompanyMenu = () => {
    setCompanyMenu((prevState) => !prevState);

    if (!isCompanyMenu) {
      setCustomerMenu(false);
      setProductMenu(false);
      setDocumentMenu(false);
      setPdfDesigner(false);
    }
  };

  const openCustomerMenu = () => {
    setCustomerMenu((prevState) => !prevState);

    if (!isCustomerMenu) {
      setCompanyMenu(false);
      setProductMenu(false);
      setDocumentMenu(false);
      setPdfDesigner(false);
    }
  };

  const openProductMenu = () => {
    setProductMenu((prevState) => !prevState);

    if (!isProductMenu) {
      setCustomerMenu(false);
      setCompanyMenu(false);
      setDocumentMenu(false);
      setPdfDesigner(false);
    }
  };

  const openDocumentMenu = () => {
    setDocumentMenu((prevState) => !prevState);

    if (!isDocumentMenu) {
      setCustomerMenu(false);
      setProductMenu(false);
      setCompanyMenu(false);
      setPdfDesigner(false);
    }
  };

  const openPdfDesigner = () => {
    setPdfDesigner((prevState) => !prevState);

    if (!isProductMenu) {
      setCustomerMenu(false);
      setCompanyMenu(false);
      setDocumentMenu(false);
      setProductMenu(false);
    }
  };

  useEffect(() => {
    const getSettings = async () => {
      const userCollection = query(
        collection(db, "users"),
        where("email", "==", currentUser.email)
      );
      const userData = await getDocs(userCollection);
      const userArray = userData.docs.map((userDoc) => ({ ...userDoc.data() }));
      setCompany(userArray[0].companyId);

      const settingsCollection = query(
        collection(db, "settings"),
        where("__name__", "==", userArray[0].companyId)
      );
      const data = await getDocs(settingsCollection);
      let settings;
      if (data !== null && data !== undefined && data.docs.length > 0) {
        settings = data.docs.map((setDoc) => ({
          ...setDoc.data(),
          id: setDoc.id,
        }));
      } else {
        try {
          await setDoc(doc(db, "settings", userArray[0].companyId), {});
        } catch (error) {
          console.log(error);
        }
      }
      setSettings(settings);

      if(settings !== null) {
        createAddColumns();
      }
    };
    getSettings();
  }, [currentUser.email]);

  const createAddColumns = () => {
    let defaultAddColumns = [
      {
        name: "customerdata",
        childs: [{
          name: "name",
        },
        {
          name: "name2",
        },
        {
          name: "reference",
        },
        {
          name: "contactName",
        },
        {
          name: "contactPhone",
        },
        {
          name: "contactEmail",
        },
        {
          name: "contactWebsite",
        },
        {
          name: "vatNumber",
        },
        {
          name: "comment",
        }
        ],
      },
      {
        name: "documentdata",
        childs: [{
          name: "number",
        }],
      },
      {
        name: "companydata",
        childs: [{
          name: "name",
        },
        {
          name: "street",
        },
        {
          name: "city",
        },
        {
          name: "phone",
        },
        {
          name: "email",
        },
        {
          name: "web",
        },
        {
          name: "logo",
        }],
      },
    ];

    



   setAddColumns(defaultAddColumns);
  };

  return (
    <>
      <Navbar />
      <p className="title">{translation("setting")}</p>
      <div className="card-box">
        <div className="setting-card" onClick={openCompanyMenu}>
          <CompanySvg className="fill-white h-10 w-10 mt-3" alt="Company" />
          <div className="setting-container">
            <h4>
              <b>{translation("company")}</b>
            </h4>
          </div>
        </div>
        <div className="setting-card" onClick={openDocumentMenu}>
          <FormulaSvg className="fill-white h-10 w-10 mt-3" alt="Invoices" />
          <div className="setting-container">
            <h4>
              <b>{translation("invoice")}</b>
            </h4>
          </div>
        </div>
        <div className="setting-card" onClick={openCustomerMenu}>
          <CustomerSvg className="fill-white h-10 w-10 mt-3" alt="Customers" />
          <div className="setting-container">
            <h4>
              <b>{translation("customers")}</b>
            </h4>
          </div>
        </div>
        <div className="setting-card" onClick={openProductMenu}>
          <ProductSvg className="fill-white h-10 w-10 mt-3" alt="Product" />
          <div className="setting-container">
            <h4>
              <b>{translation("products")}</b>
            </h4>
          </div>
        </div>
        <div className="setting-card" onClick={openPdfDesigner}>
          <PdfDesignerSvg className="fill-white h-10 w-10 mt-3" alt="PdfDesigner" />
          <div className="setting-container">
            <h4>
              <b>{translation("pdfdesigner")}</b>
            </h4>
          </div>
        </div>
      </div>

      {isCompanyMenu && <CompanySettingsMenu
        isCompanyMenu={isCompanyMenu}
        company={company}
        current={settingsCol}
        closeFunction={setCompanyMenu}
      />}
      {isDocumentMenu && <DocumentSettingsMenu
        isDocumentMenu={isDocumentMenu}
        company={company}
        current={settingsCol}
        closeFunction={setDocumentMenu}
      />}
      {isCustomerMenu && <CustomerSettingsMenu
        isCustomerMenu={isCustomerMenu}
        company={company}
        current={settingsCol}
        closeFunction={setCustomerMenu}
      />}
      {isProductMenu && <ProductSettingsMenu
        isProductMenu={isProductMenu}
        company={company}
        current={settingsCol}
        closeFunction={setProductMenu}
      />}
      {isPdfDesigner && <PdfDesigner
        isPdfDesigner={isPdfDesigner}
        company={company}
        current={settingsCol}
        addColumns={addColumns}
        closeFunction={setPdfDesigner}
      />}

      {isDocumentMenu && <ReactDimmer
        isOpen={isDocumentMenu}
        exitDimmer={setDocumentMenu}
        zIndex={103}
        blur={1.5}
      />}
      {isCompanyMenu && <ReactDimmer
        isOpen={isCompanyMenu}
        exitDimmer={setCompanyMenu}
        zIndex={103}
        blur={1.5}
      />}
      {isProductMenu && <ReactDimmer
        isOpen={isProductMenu}
        exitDimmer={setProductMenu}
        zIndex={103}
        blur={1.5}
      />}
      {isCustomerMenu && <ReactDimmer
        isOpen={isCustomerMenu}
        exitDimmer={setCustomerMenu}
        zIndex={103}
        blur={1.5}
      />}
      {isPdfDesigner && <ReactDimmer
        isOpen={isPdfDesigner}
        exitDimmer={setPdfDesigner}
        addColumns={addColumns}
        zIndex={103}
        blur={1.5}
      />}

      <Footer />
    </>
  );
};
