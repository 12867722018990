import React, { useEffect, useState, Fragment } from "react";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./Customermanagement.css";
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import translation from "../language/TranslationProvider";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";

export default function Timeplanning() {
  const [calendarweek, setCalendarweek] = useState();
  const [company, setCompany] = useState("");
  const { currentUser } = useAuth();
  const [times, setTimes] = useState([
    {
      timeStart: "18:00",
      timeEnd: "18:00",
      comment: "",
    },
    {
      timeStart: "18:15",
      timeEnd: "18:15",
      comment: "",
    },
    {
      timeStart: "18:30",
      timeEnd: "18:30",
      comment: "",
    },
  ]);
  dayjs.extend(weekOfYear)

  useEffect(() => {
    const getTimes = async () => {
      var cw = dayjs(new Date().toISOString().split('T')).week()
      setCalendarweek(cw);

    };
    getTimes();
  }, [company, currentUser.email]);

  const handleFormChangeTimes = (index, event) => {
    let data = [...times];
    data[index][event.target.name] = event.target.value;
    setTimes(data);
  };

  return (
    <>
      <Navbar />
      <p className="title">{translation("timeplanning") + " " + translation("calendarweek") + ": " + calendarweek}</p>
      {times.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">  
                <div className="settingsInputFieldGroup">
                      <input
                        className="w-12"
                        name="timeStart"
                        autoComplete="off"
                        onChange={(event) =>
                          handleFormChangeTimes(index, event)
                        }
                        value={input.timeStart}
                      />
                      <label className="settinglabel"> - </label>
                      <input
                        className="w-12"
                        name="timeEnd"
                        autoComplete="off"
                        onChange={(event) =>
                          handleFormChangeTimes(index, event)
                        }
                        value={input.timeEnd}
                      />
                  
                  <input
                    name="comment"
                    autoComplete="off"
                    onChange={(event) =>
                      handleFormChangeTimes(index, event)
                    }
                    value={input.value}
                  />
                </div>
              </div>
            </div>
          );
        })}
      <Footer />
    </>
  );
}
