import React, { useEffect, useState, Fragment } from "react";
import { ReactDimmer } from "react-dimmer";
import { NewUserMenu } from "./forms/NewUserMenu.js";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./Customermanagement.css";
import BaseDataTable from "./core/BaseDataTable";
import translation from "../language/TranslationProvider";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";

import { ReactComponent as DeleteSvg } from "../icons/delete.svg";
import { ReactComponent as NewSvg } from "../icons/new.svg";
import { ReactComponent as OpenSvg } from "../icons/open.svg";

const columns = [
  {
    name: translation("titleFirstName"),
    selector: (row) => row.firstname,
    sortable: true,
  },
  {
    name: translation("titleLastName"),
    selector: (row) => row.lastname,
    sortable: true,
  },
  {
    name: translation("titleUserName"),
    selector: (row) => row.username,
    sortable: true,
  },
  {
    name: translation("titleEmail"),
    selector: (row) => row.email,
    sortable: true,
  },
];

export default function Usermanagement() {
  const [users, setUsers] = useState([]);
  const [isNewUser, setNewUser] = useState(false);
  const [isNewUserDataSet, setNewUserDataSet] = useState(true);
  const [current, setCurrent] = useState([]);
  const [company, setCompany] = useState("");
  const { currentUser } = useAuth();
  const [settings, setSettings] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      let cachedUsers = localStorage.getItem("users");
      let newDate = Date.now();

      let companyId = company;
      if (company === null || company === undefined || company === "") {
        const userCollection = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );
        const userData = await getDocs(userCollection);
        const userArray = userData.docs.map((userDoc) => ({
          ...userDoc.data(),
        }));
        companyId = userArray[0].companyId;
        setCompany(userArray[0].companyId);
      }

      const settingsCollection = query(
        collection(db, "settings"),
        where("__name__", "==", companyId)
      );
      const data = await getDocs(settingsCollection);
      setSettings(
        data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
      );

      if (
        cachedUsers !== null &&
        cachedUsers !== undefined &&
        cachedUsers.length > 0
      ) {
        cachedUsers = JSON.parse(cachedUsers);
        const usersCollection = query(
          collection(db, "users"),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(usersCollection);
        let editedUsers = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        editedUsers.forEach((element) => {
          let index = cachedUsers.findIndex(
            (element2) => element2.id === element.id
          );
          if (index !== null && index !== undefined && index !== -1) {
            cachedUsers[index] = element;
          } else {
            cachedUsers.push(element);
          }
        });

        setUsers(cachedUsers);
        UseFilter(cachedUsers);
        localStorage.setItem("users", JSON.stringify(cachedUsers));
      } else {
        const usersCollection = query(
          collection(db, "users"),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(usersCollection);
        let userList = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        setUsers(userList);
        UseFilter(userList);
        localStorage.setItem("users", JSON.stringify(userList));
      }
    };
    getUsers();
  }, [company, currentUser.email]);

  async function openNewUser(isNewDataSet) {
    setNewUser((prevState) => !prevState);
    setNewUserDataSet(isNewDataSet);

    if (!isNewDataSet) {
      const usersCollection = query(
        collection(db, "users"),
        where("__name__", "==", selectedRow.id)
      );
      const data = await getDocs(usersCollection);
      if (data !== null && data !== undefined && data.docs.length > 0) {
        setCurrent(
          data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
        );
      }
    } else {
      setCurrent(null);
    }
  }

  const [selectedRow, setSelectedRow] = useState("");

  const deleteUser = async () => {
    await updateDoc(doc(db, "users", selectedRow.id), {
      dataState: 0, //deleted
      editDate: Date.now(),
    });
    const newList = users.filter((item) => item.id !== selectedRow.id);
    setUsers(newList);
    UseFilter(newList);
    localStorage.setItem("users", JSON.stringify(newList));
  };

  const changeFilter = () => {
    UseFilter(users);
  };

  function UseFilter(userarray) {
    let value = document.getElementById("filter").value;
    if (!value || value === null || value === undefined || value === "") {
      setFilteredUsers(userarray); 
    } else {
      setFilteredUsers(
        userarray.filter(
          (item) =>
            ((item.email !== null &&
              item.email !== undefined &&
              item.email.toLowerCase().includes(value.toLowerCase())) ||
              (item.firstname !== null &&
                item.firstname !== undefined &&
                item.firstname.toLowerCase().includes(value.toLowerCase())) ||
              (item.username !== null &&
                item.username !== undefined &&
                item.username.toLowerCase().includes(value.toLowerCase())) ||
              (item.lastname !== null &&
                item.lastname !== undefined &&
                item.lastname
                  .toLowerCase()
                  .includes(value.toLowerCase())))
        )
      );
    }
  }

  return (
    <>
      <Navbar />
      <p className="title">{translation("usermanagement")}</p>
      <div className="buttons">
        <input
          id="filter"
          className="filter"
          onChange={changeFilter}
          type="text"
          placeholder={translation("search")}
          aria-label="Search Input"
        />
        <button className="open" onClick={() => openNewUser(false)}>
          {" "}
          <OpenSvg title={translation("open")} alt="Open" />{" "}
        </button>
        {/*
        <button className="new" onClick={() => openNewUser(true)}>
          {" "}
          <NewSvg title={translation("new")} alt="New" />{" "}
        </button>
        <button className="delete" onClick={deleteUser}>
          {" "}
          <DeleteSvg title={translation("delete")} alt="Delete" />
        </button>
        */}
      </div>
      <ReactDimmer
        isOpen={isNewUser}
        exitDimmer={setNewUser}
        zIndex={103}
        blur={1.5}
      />
      <BaseDataTable
        columns={columns}
        data={filteredUsers}
        onSelect={setSelectedRow}
        currentRow={selectedRow}
        rowDoubleClicked={openNewUser}
      />
      {isNewUser && <NewUserMenu
        key={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        current={current}
        company={company}
        isNewUser={isNewUser}
        isNewUserDataSet={isNewUserDataSet}
        settings={settings}
        userId={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        closeFunction={setNewUser}
      />}
      <Footer />
    </>
  );
}
