import React, { useRef, useState } from "react";
import "./FormMenu.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast, { Toaster } from "react-hot-toast";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const CompanySettingsMenu = ({ isCompanyMenu, company, current, closeFunction }) => {
  const companyNameRef = useRef();
  const companyCityRef = useRef();
  const companyPlzRef = useRef();
  const companyStreetRef = useRef();
  const companyPhoneRef = useRef();
  const companyEmailRef = useRef();
  const companyWebRef = useRef();
  const companyBanknameRef = useRef();
  const companyIbanRef = useRef();
  const companyBicRef = useRef();
  const companyTaxNoRef = useRef();
  const companyUstIdRef = useRef();
  const companyCeoRef = useRef();
 const [file, setFile] = useState(null); 
  const [url, setURL] = useState("");

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "settings", company), {
        company: {
          name: companyNameRef.current.value,
          plz: companyPlzRef.current.value,
          street: companyStreetRef.current.value,
          city: companyCityRef.current.value,
          phone: companyPhoneRef.current.value,
          email: companyEmailRef.current.value,
          web: companyWebRef.current.value,
          bankname: companyBanknameRef.current.value,
          iban: companyIbanRef.current.value,
          bic: companyBicRef.current.value,
          ustId: companyUstIdRef.current.value,
          taxNo: companyTaxNoRef.current.value,
          ceo: companyCeoRef.current.value,
        },
      });

      if (
        (file !== null && file !== undefined && file !== "") ||
        url === null ||
        url === undefined ||
        url === ""
      ) {
        console.log(file);

        const storage = getStorage();
        const storageRef = ref(storage, "logo/" + company);
        uploadBytes(storageRef, file).then((snapshot) => {
          console.log("Logo uploaded!");
        });
      }

      toast.success(translation("saveSuccess"));
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function handleChange(e) {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setURL(URL.createObjectURL(e.target.files[0]));
    } else {
      setFile(undefined);
      setURL(undefined);
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {
      if (
        column === "logo" &&
        (url === undefined || url === null || url === "")
      ) {
        const storage = getStorage();
        const starsRef = ref(storage, "logo/" + company);
        getDownloadURL(starsRef).then((url) => {
          setURL(url);
        });
      }

      if (column !== "logo") {
        try {
          return current[0].company[column];
        } catch {}
      }
    }
    return "";
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className={`invoice-menu ${isCompanyMenu ? "invoice-open" : ""}`}>
        <form className="settingForm" onSubmit={update}>
          <h1 className="settingsTitle">{translation("companySettings")}</h1>
          <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
          <label className="settinglabel">{translation("companyName")}</label>
          <input
            className="settingInput"
            ref={companyNameRef}
            autoComplete="off"
            defaultValue={getValue("name")}
          />
          <label className="settinglabel">{translation("companyPlz")}</label>
          <input
            className="settingInput"
            ref={companyPlzRef}
            autoComplete="off"
            defaultValue={getValue("plz")}
          />
          <label className="settinglabel">{translation("companyStreet")}</label>
          <input
            className="settingInput"
            ref={companyStreetRef}
            autoComplete="off"
            defaultValue={getValue("street")}
          />
          <label className="settinglabel">{translation("companyCity")}</label>
          <input
            className="settingInput"
            ref={companyCityRef}
            autoComplete="off"
            defaultValue={getValue("city")}
          />
          <label className="settinglabel">{translation("companyPhone")}</label>
          <input
            className="settingInput"
            ref={companyPhoneRef}
            autoComplete="off"
            defaultValue={getValue("phone")}
          />
          <label className="settinglabel">{translation("companyEmail")}</label>
          <input
            className="settingInput"
            ref={companyEmailRef}
            autoComplete="off"
            defaultValue={getValue("email")}
          />
          <label className="settinglabel">{translation("companyWeb")}</label>
          <input
            className="settingInput"
            ref={companyWebRef}
            autoComplete="off"
            defaultValue={getValue("web")}
          />
          <label className="settinglabel">{translation("companyBankanme")}</label>
          <input
            className="settingInput"
            ref={companyBanknameRef}
            autoComplete="off"
            defaultValue={getValue("bankname")}
          />
          <label className="settinglabel">{translation("companyIban")}</label>
          <input
            className="settingInput"
            ref={companyIbanRef}
            autoComplete="off"
            defaultValue={getValue("iban")}
          />
          <label className="settinglabel">{translation("companyBic")}</label>
          <input
            className="settingInput"
            ref={companyBicRef}
            autoComplete="off"
            defaultValue={getValue("bic")}
          />
          <label className="settinglabel">{translation("companyTaxNo")}</label>
          <input
            className="settingInput"
            ref={companyTaxNoRef}
            autoComplete="off"
            defaultValue={getValue("taxNo")}
          />
          <label className="settinglabel">{translation("companyUstId")}</label>
          <input
            className="settingInput"
            ref={companyUstIdRef}
            autoComplete="off"
            defaultValue={getValue("ustId")}
          />
           <label className="settinglabel">{translation("companyCeo")}</label>
          <input
            className="settingInput"
            ref={companyCeoRef}
            autoComplete="off"
            defaultValue={getValue("ceo")}
          />

          <label className="settingsUploadLogo">
            <i></i>
            {translation("uploadCompanyLogo")}
            <input
              defaultValue={getValue("logo")}
              accept=".jpg,.png"
              onChange={handleChange}
              type="file"
              style={{ display: "none" }}
              name="image"
            />
          </label>
          {url !== undefined && url !== null && (
            <img className="settingLogoPreview" src={url} alt="logo"/>
          )}

          <input
            className="settingSubmit"
            type="submit"
            value={translation("save")}
          />
        </form>
      </div>
    </>
  );
};
