import language from "./language.json";

export default function translation(translationKey) {
  try {
    let lang = localStorage.langCode;
    if (lang === null || lang === undefined || lang === "") {
      lang = (navigator.language || navigator.userLanguage).substring(0, 2);
    }

    let trans = language[lang][translationKey];
    if (trans === null || trans === undefined || trans === "") {
      console.log("\"" + translationKey + "\": \"\", " )
      return translationKey;
    }
    return trans;
  } catch (e) {
    console.log("\"" + translationKey + "\": \"\", " )
    return translationKey;
  }
}
