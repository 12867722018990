import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="outerfooter">
      <div className="innerfooter">
        <span>Made with ️❤️ in Germany | </span>
        <a
          className="footerName"
          href="https://halfwork.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Halfwork
        </a>
        <span>&copy; 2023 Halfwork.de</span>
      </div>
    </div>
  );
};
export default Footer;
