import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ReactDimmer } from "react-dimmer";
import { ProgrammMenu } from "./ProgrammMenu.js";
import {
  Popover,
  OverlayTrigger,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import translation from "../../language/TranslationProvider";

import { ReactComponent as UserSvg } from "../../icons/user.svg";
import { ReactComponent as CustomerSvg } from "../../icons/customer.svg";
import { ReactComponent as ProductSvg } from "../../icons/products.svg";
import { ReactComponent as FormulaSvg } from "../../icons/formula.svg";
import { ReactComponent as SettingSvg } from "../../icons/settings.svg";
import { ReactComponent as MoreSvg } from "../../icons/more.svg";
import { ReactComponent as LogoSvg } from "../../icons/logoWhiteFull.svg";
import { ReactComponent as UserConfSvg } from "../../icons/userConf.svg";


import "./Navbar.css";

function Navbar() {
  const [user, setUser] = useState(false);
  const handleUserClick = () => setUser(!user);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [isLangNotSupp, setLangNotSupp] = useState(false);

  const handleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await logout();
      navigate("/app/login");
    } catch {}
  }

  function getLanguage() {
    let currentLang = "";
    if (
      localStorage.langCode === null ||
      localStorage.langCode === undefined ||
      localStorage.langCode === ""
    ) {
      currentLang = (navigator.language || navigator.userLanguage).substring(
        0,
        2
      );
    } else {
      currentLang = localStorage.langCode;
    }

    let languageTrsl = translation("langName");

    if ((languageTrsl !== "langName") === isLangNotSupp) {
      setLangNotSupp(!isLangNotSupp);
    }

    if (languageTrsl !== "langName") {
      currentLang = languageTrsl;
    }

    return translation("language") + ": " + currentLang;
  }

  function handleLanguageClick(lang) {
    if (lang.target.id !== localStorage.langCode) {
      localStorage.langCode = lang.target.id;
      window.location.reload();
    }
  }

  const popover = (
    <Popover id="popover-contained">
      <Popover.Header as="h3">{translation("profile")}</Popover.Header>
      <Popover.Body>
        {isLangNotSupp && (
          <p id="langNotSupp" title="Language not supported!">
            &#9888;
          </p>
        )}
        <Dropdown>
          <Dropdown.Toggle className="w-100">{getLanguage()}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item id="de" onClick={(e) => handleLanguageClick(e)}>
              Deutsch
            </Dropdown.Item>
            <Dropdown.Item id="en" onClick={(e) => handleLanguageClick(e)}>
              English
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Form onSubmit={handleSubmit}>
          <Button
            className="w-100 mt-1 border-[#FF4748] bg-[#FF4748] hover:border-[#ff6768] hover:bg-[#ff6768] focus:border-[#ff6768] focus:bg-[#ff6768]"
            type="submit"
          >
            {translation("logout")}
          </Button>
        </Form>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <ul className="programm-menu">
            <li className="programm-liste">
              <MoreSvg
                className="fill-white h-6 w-6"
                title={translation("module")}
                alt="Module"
                onClick={handleMenu}
              />
              <ProgrammMenu isMenuOpen={isMenuOpen} />
              <ReactDimmer
                title={translation("module")}
                isOpen={isMenuOpen}
                exitDimmer={setMenuOpen}
                zIndex={100}
                blur={1.5}
              />
            </li>
            <a className="programm" href="/app/customermanagement">
              <CustomerSvg
                className="fill-white h-6 w-6"
                title={translation("customers")}
                alt="Customers"
              />
            </a>
            <li className="vl" />
            <a className="programm" href="/app/products">
              <ProductSvg
                className="fill-white h-6 w-6"
                title={translation("products")}
                alt="Products"
              />
            </a>
            <li className="vl" />
            <a className="programm" href="/app/documents">
              <FormulaSvg
                className="fill-white h-5 w-5"
                title={translation("documents")}
                alt="Documents"
              />
            </a>
            {/*
            <li className="vl" />
            <a className="programm" href="/app/timeplanning">
              <TimeplanningSvg
                className="fill-white h-6 w-6"
                title={translation("timeplanning")}
                alt="Timeplanning"
              />
            </a>
            */}
            <li className="vl" />
            <a className="programm" href="/app/usermanagement">
              <UserConfSvg
                className="fill-white h-6 w-6"
                title={translation("usermanagement")}
                alt="User management"
              />
            </a>
          </ul>

          <a href="/app/" className="navbar-logo">
            <LogoSvg alt="Halfwork" />
          </a>

          <ul className="right-menu">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popover}
            >
              <li className="navbar-icon" onClick={handleUserClick}>
                <UserSvg
                  className="fill-white h-6 w-6"
                  title={translation("profile")}
                  alt="Profile"
                />
              </li>
            </OverlayTrigger>
            <li className="vl2" />
            <a className="navbar-icon" href="/app/settings">
              <SettingSvg
                className="fill-white h-6 w-6"
                title={translation("setting")}
                alt="Settings"
              />
            </a>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
