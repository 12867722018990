import React, { useState } from "react";
import "./FormMenu.css";
import "./PdfDesigner.css";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast, { Toaster } from "react-hot-toast";
import Draggable from "react-draggable";
import Collapsible from "react-collapsible";


export const PdfDesigner = ({ isPdfDesigner, company, current, addColumns, closeFunction }) => {
 const [isAlreadyLoaded, setAlreadyLoaded] = useState(false);
 const [inputFields, setInputFields] = useState([
   {
     title: "",
     code: "",
     value: "",
   },
 ]);


 const [draggableFields, setDraggableFields] = useState([]);

 async function update(e) {
  e.preventDefault();
  //input3.deltaPosition.x * 0.26455026456  
  //input3.deltaPosition.y * 0.26455026456  
  console.log(draggableFields)
}

 async function update2(e) {
   e.preventDefault();


   try {
     await updateDoc(doc(db, "settings", company), {
       product: {
         additionalProperties: inputFields,
       },
     });
     toast.success(translation("saveSuccess"));
   } catch (error) {
     console.error(error);
     toast.error(translation("saveError"));
   }
 }


 function getValue(column) {
   if (current !== null && current !== undefined && current.length > 0) {
     let inputFieldsSet = false;
     if (
       column === "init" &&
       current[0].product["additionalProperties"] !== null &&
       current[0].product["additionalProperties"] !== undefined &&
       current[0].product["additionalProperties"].length > 0 &&
       isAlreadyLoaded === false
     ) {
       inputFieldsSet = true;
       setAlreadyLoaded(true);
       setInputFields(current[0].product["additionalProperties"]);
     } else if (
       column === "init" &&
       inputFieldsSet === false &&
       isAlreadyLoaded === false
     ) {
       setAlreadyLoaded(true);
       setInputFields([
         {
           title: "",
           code: "",
           value: "",
         },
       ]);
     }
     try {
       return current[0].product[column];
     } catch {}
   }
   return "";
 }


 const handleFormChange = (index, event) => {
   let data = [...inputFields];
   data[index][event.target.name] = event.target.value;
   setInputFields(data);
 };


 const addDragableItem = (e, name, name2) => {
  e.preventDefault();

  let newfield = {
    name: name2.concat('.', name),
    deltaPosition: {
      x: 0,
      y: 0,
    },
  };

  setDraggableFields([...draggableFields, newfield]);
};


 const removeAdditionalPropertyField = (e, index) => {
   e.preventDefault();


   let data = [...inputFields];
   data.splice(index, 1);
   setInputFields(data);
 };


 const handleDrag = (e, ui, input, index) => {
   let data = [...draggableFields];
   const { x, y } = input.deltaPosition;

   data[index]["deltaPosition"] = {
     x: x + ui.deltaX,
     y: y + ui.deltaY,
   };
   setDraggableFields(data);
 };

 return (
   <>
     <Toaster position="top-right" reverseOrder={true} />
     <div
       className={`pdfdesigner-menu ${
         isPdfDesigner ? "pdfdesigner-open" : ""
       }`}
     >
       <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
       <form className="formPdfDesigner" onSubmit={update}>
         <div className="pdfblank3">
           <div className="pdfblankScroll">
             <div className="pdfblank">
               {draggableFields.map((input3, index3) => {
                 return (
                   <Draggable
                       key={index3}
                       bounds="parent"
                       defaultPosition={{x: input3.deltaPosition.x, y: input3.deltaPosition.y - (index3 * 26.6)}}
                       onDrag={(e, ui) => handleDrag(e, ui, input3, index3)}>
                     <div className="rect">
                        {input3.name} 
                     </div>
                   </Draggable>
                 );
               })}
             </div>
           </div>


           <div className="pdfblankScroll">
             <div className="pdfblank2">
               <input
                 className="settingSubmit"
                 type="submit"
                 value={translation("save")}
               />
               {addColumns.map((input, index) => {
                 return (
                   <div key={index}>
                     <Collapsible
                       classParentString="pdfDesignerCollapse"
                       trigger={input.name + " -"}
                       triggerWhenOpen={input.name + " +"}
                     >
                       {input.childs.map((input2, index2) => {
                         return (
                           <div key={index2} className="pdfDesignerCollapseItem">
                             {input2.name}
                             <button
                               className="pdfDesignerAddItem"
                               onClick={(e) => addDragableItem(e, input2.name, input.name)}
                             >
                               +
                             </button>
                           </div>
                         );
                       })}
                     </Collapsible>
                   </div>
                 );
               })}
             </div>
           </div>
         </div>
       </form>
     </div>
   </>
 );
};





