import React, { useEffect, useState, Fragment } from "react";
import { ReactDimmer } from "react-dimmer";
import { NewProductMenu } from "./forms/NewProductMenu.js";
import { NewProductGroupMenu } from "./forms/NewProductGroupMenu.js";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./Customermanagement.css";
import BaseDataTable from "./core/BaseDataTable";
import translation from "../language/TranslationProvider";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";

import { ReactComponent as DeleteSvg } from "../icons/delete.svg";
import { ReactComponent as NewSvg } from "../icons/new.svg";
import { ReactComponent as OpenSvg } from "../icons/open.svg";
import { ReactComponent as GroupingSvg } from "../icons/grouping.svg";

const columns = [
  {
    name: translation("titleProductNumber"),
    selector: (row) => row.number,
    sortable: true,
  },
  {
    name: translation("titleProductName"),
    selector: (row) => row.productName,
    sortable: true,
  },
  {
    name: translation("titleProductCategory"),
    selector: (row) => row.productCategory,
    sortable: true,
  },
];

export default function Products() {
  const [products, setProducts] = useState([]);
  const [isNewProduct, setNewProduct] = useState(false);
  const [isNewProductDataSet, setNewProductDataSet] = useState(true);
  const [current, setCurrent] = useState([]);
  const [company, setCompany] = useState("");
  const { currentUser } = useAuth();
  const [settings, setSettings] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isProductGroup, setProductGroup] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      let cachedProducts = null;
      let newDate = Date.now();

      let companyId = company;
      if (company === null || company === undefined || company === "") {
        const userCollection = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );
        const userData = await getDocs(userCollection);
        const userArray = userData.docs.map((userDoc) => ({
          ...userDoc.data(),
        }));
        companyId = userArray[0].companyId;
        setCompany(userArray[0].companyId);
      }

      const settingsCollection = query(
        collection(db, "settings"),
        where("__name__", "==", companyId)
      );
      const data = await getDocs(settingsCollection);
      setSettings(
        data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
      );

      if (
        cachedProducts !== null &&
        cachedProducts !== undefined &&
        cachedProducts.length > 0
      ) {
        cachedProducts = JSON.parse(cachedProducts);
        let lastDate = null;
        const productsCollection = query(
          collection(db, "products"),
          where("editDate", ">=", lastDate),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(productsCollection);
        let editedProducts = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        editedProducts.forEach((element) => {
          let index = cachedProducts.findIndex(
            (element2) => element2.id === element.id
          );
          if (index !== null && index !== undefined && index !== -1) {
            cachedProducts[index] = element;
          } else {
            cachedProducts.push(element);
          }
        });

        setProducts(cachedProducts);
        UseFilter(cachedProducts);
      } else {
        const productsCollection = query(
          collection(db, "products"),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(productsCollection);
        let productList = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        setProducts(productList);
        UseFilter(productList);
      }
    };
    getProducts();
  }, [company, currentUser.email]);

  async function openNewProduct(isNewDataSet) {
    setNewProduct((prevState) => !prevState);
    setNewProductDataSet(isNewDataSet);

    if (!isNewDataSet) {
      const productsCollection = query(
        collection(db, "products"),
        where("__name__", "==", selectedRow.id)
      );
      const data = await getDocs(productsCollection);
      if (data !== null && data !== undefined && data.docs.length > 0) {
        setCurrent(
          data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
        );
      }
    } else {
      setCurrent(null);
    }
  }

  const [selectedRow, setSelectedRow] = useState("");

  const deleteProduct = async () => {
    await updateDoc(doc(db, "products", selectedRow.id), {
      dataState: 0, //deleted
      editDate: Date.now(),
    });
    const newList = products.filter((item) => item.id !== selectedRow.id);
    setProducts(newList);
    UseFilter(newList);
    localStorage.setItem("products", JSON.stringify(newList));
  };

  const changeFilter = () => {
    UseFilter(products);
  };

  function UseFilter(productrray) {
    let value = document.getElementById("filter").value;
    if (!value || value === null || value === undefined || value === "") {
      setFilteredProducts(
        productrray.filter(
          (item) =>
            item.dataState === null ||
            item.dataState === undefined ||
            item.dataState !== 0
        )
      );
    } else {
      setFilteredProducts(
        productrray.filter(
          (item) =>
            (item.dataState === null ||
              item.dataState === undefined ||
              item.dataState !== 0) &&
            ((item.number !== null &&
              item.number !== undefined &&
              item.number.toLowerCase().includes(value.toLowerCase())) ||
              (item.productName !== null &&
                item.productName !== undefined &&
                item.productName.toLowerCase().includes(value.toLowerCase())) ||
              (item.productCategory !== null &&
                item.productCategory !== undefined &&
                item.productCategory
                  .toLowerCase()
                  .includes(value.toLowerCase())))
        )
      );
    }
  }

  return (
    <>
      <Navbar />
      <p className="title">{translation("products")}</p>
      <div className="buttons">
        <input
          id="filter"
          className="filter"
          onChange={changeFilter}
          type="text"
          placeholder={translation("search")}
          aria-label="Search Input"
        />
        <button className="open" onClick={() => openNewProduct(false)}>
          {" "}
          <OpenSvg title={translation("open")} alt="Open" />{" "}
        </button>
        <button className="new" onClick={() => openNewProduct(true)}>
          {" "}
          <NewSvg title={translation("new")} alt="New" />{" "}
        </button>
        <button className="grouping" onClick={() => setProductGroup(true)}>
          {" "}
          <GroupingSvg title={translation("grouping")} alt="Grouping" />{" "}
        </button>
        <button className="delete" onClick={deleteProduct}>
          {" "}
          <DeleteSvg title={translation("delete")} alt="Delete" />
        </button>
      </div>
      <ReactDimmer
        isOpen={isNewProduct}
        exitDimmer={setNewProduct}
        zIndex={103}
        blur={1.5}
      />
      <BaseDataTable
        columns={columns}
        data={filteredProducts}
        onSelect={setSelectedRow}
        currentRow={selectedRow}
        rowDoubleClicked={openNewProduct}
      />
      {isNewProduct && <NewProductMenu
        key={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        current={current}
        company={company}
        isNewProduct={isNewProduct}
        isNewProductDataSet={isNewProductDataSet}
        settings={settings}
        productId={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        closeFunction={setNewProduct}
      />}
      <ReactDimmer
        isOpen={isProductGroup}
        exitDimmer={setProductGroup}
        zIndex={103}
        blur={1.5}
      />
      {isProductGroup && <NewProductGroupMenu
        key={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        current={current}
        company={company}
        isProductGroup={isProductGroup}
        isNewProductDataSet={isNewProductDataSet}
        settings={settings}
        productId={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        closeFunction={setProductGroup}
        productColumns={columns}
        filteredProducts={filteredProducts}
      />}
      <Footer />
    </>
  );
}
