import React, { useState } from "react";
import "./FormMenu.css";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast, { Toaster } from "react-hot-toast";
import { ReactComponent as DeleteSvg } from "../../icons/delete.svg";

export const ProductSettingsMenu = ({ isProductMenu, company, current, closeFunction }) => {
  const [isAlreadyLoaded, setAlreadyLoaded] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      title: "",
      code: "",
      value: "",
    },
  ]);

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "settings", company), {
        product: {
          additionalProperties: inputFields,
        },
      });
      toast.success(translation("saveSuccess"));
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  
  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {
      let inputFieldsSet = false;
      if (
        column === "init" &&
        current[0].product["additionalProperties"] !== null &&
        current[0].product["additionalProperties"] !== undefined &&
        current[0].product["additionalProperties"].length > 0 &&
        isAlreadyLoaded === false
      ) {
        inputFieldsSet = true;
        setAlreadyLoaded(true);
        setInputFields(current[0].product["additionalProperties"]);
      } else if (
        column === "init" &&
        inputFieldsSet === false &&
        isAlreadyLoaded === false
      ) {
        setAlreadyLoaded(true);
        setInputFields([
          {
            title: "",
            code: "",
            value: "",
          },
        ]);
      }
      try {
        return current[0].product[column];
      } catch {}
    }
    return "";
  }

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    data[index]["code"] = data[index]["title"];
    setInputFields(data);
  };

  const addAdditionalPropertyField = (e) => {
    e.preventDefault();

    let newfield = {
      title: "",
      code: "",
      value: "",
    };

    setInputFields([...inputFields, newfield]);
  };

  const removeAdditionalPropertyField = (e, index) => {
    e.preventDefault();

    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />
      <div className={`invoice-menu ${isProductMenu ? "invoice-open" : ""}`}>
        <form className="settingForm" onSubmit={update}>
          <h1 className="settingsTitle">{translation("productSettings")}</h1>
          <button className="closeForm z-200" onClick={() => closeFunction(false)}>X</button>
          <h2
            className="settingsSectionTitle mb-2"
            defaultValue={getValue("init")}
          >
            {translation("AdditionalProperties")}{" "}
          </h2>
          {inputFields.map((input, index) => {
            return (
              <div key={index}>
                <div className="settingsInputGroup mt-2">
                  <button
                    className="remove-button mt-0"
                    onClick={(e) => removeAdditionalPropertyField(e, index)}
                  >
                    <DeleteSvg
                      title={translation("RemoveAdditionalPropertyToolTip")}
                    ></DeleteSvg>
                  </button>
                  <div className="settingsInputFieldGroup mt-0">
                    <input
                      name="title"
                      required
                      className="settingInput"
                      onChange={(event) => handleFormChange(index, event)}
                      value={input.title}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <button
            className="add-button"
            onClick={addAdditionalPropertyField}
            title={translation("AddAdditionalPropertyToolTip")}
          >
            +
          </button>

          <input
            className="settingSubmit"
            type="submit"
            value={translation("save")}
          />
        </form>
      </div>
    </>
  );
};
