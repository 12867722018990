import React, { useRef, useState } from "react";
import "./NewInvoiceMenu.css";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast from "react-hot-toast";
import "react-dropdown/style.css";
import { ReactComponent as LoadingSvg } from "../../icons/choose.svg";
import { ReactComponent as DeleteSvg } from "../../icons/delete.svg";

export const NewInvoiceMenu = ({
  isNewInvoice,
  company,
  isNewInvoiceDataSet,
  invoiceId,
  current,
  customerName,
  address,
  customerId,
  closeFunction
}) => {
  const invoiceNoRef = useRef();
  const invoiceDateRef = useRef();
  const invoiceDelDateRef = useRef();
  const customerRef = useRef();
  const streetRef = useRef();
  const plzRef = useRef();
  const cityRef = useRef();
  const offerNoRef = useRef();
  const offerDateRef = useRef();
  const offerEndDateRef = useRef();

  const [positionFields, setPositionFields] = useState([
    {
      addressStreet: "",
      addressStreet2: "",
      addressZipcode: "",
      addressCity: "",
      addressState: "",
    },
  ]);

  async function create(e) {
    e.preventDefault();

    try {
      const invoiceCollectionRef = collection(db, "invoices");
      await addDoc(invoiceCollectionRef, {
        companyId: company,
        offerNumber: offerNoRef.current.value,
        offerDate: offerDateRef.current.value,
        offerEndDate: offerEndDateRef.current.value,
        number: invoiceNoRef.current.value,
        invoiceDate: invoiceDateRef.current.value,
        invoiceDelDate: invoiceDelDateRef.current.value,
        customer: customerRef.current.value,
        street: streetRef.current.value,
        plz: plzRef.current.value,
        city: cityRef.current.value,
        customerId: customerId,
        newDate: Date.now(),
        editDate: Date.now(),
        dataState: 1,
      });
      window.location.reload();
    } catch {}
  }

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "invoices", invoiceId), {
        number: invoiceNoRef.current.value,
        invoiceDate: invoiceDateRef.current.value,
        invoiceDelDate: invoiceDelDateRef.current.value,
        customer: customerRef.current.value,
        street: streetRef.current.value,
        plz: plzRef.current.value,
        city: cityRef.current.value,
        customerId: customerId,
        editDate: serverTimestamp(),
      });

      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function getValue(column) {
    if(column === 'customer' && (customerName !== null && customerName !== undefined)) {
      return customerName;
    }
    if(column === 'street' && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressStreet"] + " " + address[0]["addressStreet2"];
    }
    if(column === 'city' && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressCity"];
    }
    if(column === 'plz' && (address !== null && address !== undefined && address.length > 0)) {
      return address[0]["addressZipcode"];
    }
    if (current !== null && current !== undefined && current.length > 0) {
      try {
        return current[0][column];
      } catch {}
    }
    return "";
  }

  const handleFormChange = (index, event) => {
    let data = [...positionFields];
    data[index][event.target.name] = event.target.value;
    setPositionFields(data);
  };

  const addAddressFields = (e) => {
    e.preventDefault();

    let newfield = {
      addressStreet: "",
      addressStreet2: "",
      addressZipcode: "",
      addressCity: "",
      addressState: "",
    };

    setPositionFields([...positionFields, newfield]);
  };

  const removePositionFields = (e, index) => {
    e.preventDefault();

    let data = [...positionFields];
    data.splice(index, 1);
    setPositionFields(data);
  };

  return (
    <div className={`invoice-menu ${isNewInvoice ? "invoice-open" : ""}`}>
      <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
      <form
        className="settingForm"
        onSubmit={isNewInvoiceDataSet ? create : update}
      >
        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleOfferNo")}</label>
                <input
                  className="settingInput"
                  ref={offerNoRef}
                  autoComplete="off"
                  defaultValue={getValue("offerNumber")}
                />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleOfferDate")}</label>
                <input
                  className="settingInput"
                  ref={offerDateRef}
                  autoComplete="off"
                  defaultValue={getValue("offerDate")}
                />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleOfferEndDate")}</label>
                <input
                  className="settingInput"
                  ref={offerEndDateRef}
                  autoComplete="off"
                  defaultValue={getValue("offerEndDate")}
                />
            </div>
        </div>

        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleInvoiceNo")}</label>
                <input
                  className="settingInput"
                  ref={invoiceNoRef}
                  autoComplete="off"
                  defaultValue={getValue("number")}
                />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleInvoiceDate")}</label>
                <input
                  className="settingInput"
                  ref={invoiceDateRef}
                  autoComplete="off"
                  defaultValue={getValue("invoiceDate")}
                />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleInvoiceDelDate")}</label>
                <input
                  className="settingInput"
                  ref={invoiceDelDateRef}
                  autoComplete="off"
                  defaultValue={getValue("invoiceDelDate")}
                />
            </div>
        </div>

        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleCustomer")}</label>
                    <input
                        className="settingInput"
                        ref={customerRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("customer")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleStreet")}</label>
                    <input
                        className="settingInput"
                        ref={streetRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("street")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titlePlz")}</label>
                    <input
                        className="settingInput"
                        ref={plzRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("plz")}
                    />
            </div>
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleCity")}</label>
                    <input
                        className="settingInput"
                        ref={cityRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("city")}
                    />
            </div>
        </div>

        {/* POSITIONEN */}
        <h2 className="settingsSectionTitle">{translation("Positionen")} </h2>
        {positionFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <button
                  className="remove-button"
                  onClick={(e) => removePositionFields(e, index)}
                >
                  <DeleteSvg
                    title={translation("RemoveAddressToolTip")}
                  ></DeleteSvg>
                </button>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titlePosition")}
                  </label>
                  <input
                    name="addressStreet"
                    className="settingInputSmall"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressStreet}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titleDescription")}
                  </label>
                  <input
                    name="addressStreet2"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressStreet2}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titleQuantity")}
                  </label>
                  <input
                    name="addressZipcode"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressZipcode}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titlePrice")}
                  </label>
                  <input
                    name="addressCity"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.addressCity}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <button
          className="add-button"
          onClick={addAddressFields}
          title={translation("AddAddressToolTip")}
        >
          +
        </button>

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewInvoiceDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
