import React, { useEffect, useState, Fragment } from "react";
import { ReactDimmer } from "react-dimmer";
import { NewCustomerMenu } from "./forms/NewCustomerMenu.js";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./Customermanagement.css";
import BaseDataTable from "./core/BaseDataTable";
import translation from "../language/TranslationProvider";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  doc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";

import { ReactComponent as DeleteSvg } from "../icons/delete.svg";
import { ReactComponent as NewSvg } from "../icons/new.svg";
import { ReactComponent as OpenSvg } from "../icons/open.svg";
import { ReactComponent as ConfigurationSvg } from "../icons/configuration2.svg";
import { TableConfiguration } from "./forms/TableConfiguration.js";

const columns = [
  {
    name: translation("titleCustomerName"),
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: translation("titleCustomerName2"),
    selector: (row) => row.name2,
    sortable: true,
  },
  {
    name: translation("titleCustomerReference"),
    selector: (row) => row.reference,
    sortable: true,
  },
  {
    name: translation("titleAddressStreet"),
    selector: (row) =>
      row.addresses != null && row.addresses !== undefined && row.addresses.length > 0
        ? row.addresses[0].addressStreet
        : "",
    sortable: true,
  },
  {
    name: translation("titleAddressZipcode"),
    selector: (row) =>
      row.addresses != null && row.addresses !== undefined
        ? row.addresses[0].addressZipcode
        : "",
    sortable: true,
  },
  {
    name: translation("titleAddressCity"),
    selector: (row) =>
      row.addresses != null && row.addresses !== undefined
        ? row.addresses[0].addressCity
        : "",
    sortable: true,
  },
  {
    name: translation("titleIBAN"),
    selector: (row) => getAdditionalPropertyColumnValue(row.additionalProperties, 'IBAN'),
    sortable: true,
  },
];

function getAdditionalPropertyColumnValue(additionalProperties, field) {
  if(additionalProperties !== null && additionalProperties !== undefined && additionalProperties.length > 0) {
    for(let i = 0; i < additionalProperties.length; i++) {
      if(field === additionalProperties[i].title){
        return additionalProperties[i].value;
      }
    }
  }
  return "";
}

export default function Customermanagement() {
  const [customers, setCustomers] = useState([]);
  const [isNewCustomer, setNewCustomer] = useState(false);
  const [isNewCustomerDataSet, setNewCustomerDataSet] = useState(true);
  const [current, setCurrent] = useState([]);
  const [company, setCompany] = useState("");
  const [user, setUser] = useState("");
  const { currentUser } = useAuth();
  const [settings, setSettings] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isTableConf, setTableConf] = useState(false);
  const [tableConfFields, setTableConfFields] = useState([
    {
      title: translation("titleName"),
      value: 1,
    },
    {
      title: translation("titleName2"),
      value: 1,
    },
    {
      title: translation("titleCustomerReference"),
      value: 1,
    },
    {
      title: translation("titleCustomerGroup"),
      value: 1,
    },
    {
      title: translation("titleCustomerContact"),
      value: 1,
    },
    {
      title: translation("titleContactPhone"),
      value: 1,
    },
    {
      title: translation("titleContactEmail"),
      value: 1,
    },
    {
      title: translation("titleContactWebsite"),
      value: 1,
    },
    {
      title: translation("titleVatNumber"),
      value: 1,
    },
    {
      title: translation("titleComment"),
      value: 1,
    },
  ]);

  useEffect(() => {
    const getCustomers = async () => {
      let cachedCustomers = localStorage.getItem("customers");
      let newDate = Date.now();

      let companyId = company;
      if (company === null || company === undefined || company === "") {
        const userCollection = query(
          collection(db, "users"),
          where("email", "==", currentUser.email)
        );
        const userData = await getDocs(userCollection);
        const userArray = userData.docs.map((userDoc) => ({
          ...userDoc.data(),  id: userDoc.id
        }));
        companyId = userArray[0].companyId;
        setCompany(userArray[0].companyId);
        setUser(userArray[0]);
      }

      const settingsCollection = query(
        collection(db, "settings"),
        where("__name__", "==", companyId)
      );
      const data = await getDocs(settingsCollection);
      let currSettings = data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }));
      setSettings(currSettings);

      if(currSettings !== null && currSettings !== undefined && currSettings !== "" &&
      currSettings[0] !== null && currSettings[0] !== undefined && currSettings[0] !== "" && 
      currSettings[0].customer["additionalProperties"] !== null && currSettings[0].customer["additionalProperties"] !== undefined && currSettings[0].customer["additionalProperties"] !== "") {
        let additionalProps = currSettings[0].customer["additionalProperties"];
        let newTableConfFields = tableConfFields.concat(additionalProps);
        setTableConfFields(newTableConfFields);
      }
      

      if (
        cachedCustomers !== null &&
        cachedCustomers !== undefined &&
        cachedCustomers.length > 0
      ) {
        cachedCustomers = JSON.parse(cachedCustomers);
        let lastDate = parseInt(localStorage.getItem("customersDate"));
        const customersCollection = query(
          collection(db, "customers"),
          where("editDate", ">=", lastDate),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(customersCollection);
        let editedCustomers = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        editedCustomers.forEach((element) => {
          let index = cachedCustomers.findIndex(
            (element2) => element2.id === element.id
          );
          if (index !== null && index !== undefined && index !== -1) {
            cachedCustomers[index] = element;
          } else {
            cachedCustomers.push(element);
          }
        });

        setCustomers(cachedCustomers);
        UseFilter(cachedCustomers);
        localStorage.setItem("customers", JSON.stringify(cachedCustomers));
        localStorage.setItem("customersDate", newDate);
      } else {
        const customersCollection = query(
          collection(db, "customers"),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(customersCollection);
        let customerList = data.docs.map((invDoc) => ({
          ...invDoc.data(),
          id: invDoc.id,
        }));
        setCustomers(customerList);
        UseFilter(customerList);
        localStorage.setItem("customers", JSON.stringify(customerList));
        localStorage.setItem("customersDate", newDate);
      }
    };
    getCustomers();
  }, [company, currentUser.email]);

  async function openNewCustomer(isNewDataSet) {
    setNewCustomer((prevState) => !prevState);
    setNewCustomerDataSet(isNewDataSet);

    if (!isNewDataSet) {
      const customersCollection = query(
        collection(db, "customers"),
        where("__name__", "==", selectedRow.id)
      );
      const data = await getDocs(customersCollection);
      if (data !== null && data !== undefined && data.docs.length > 0) {
        setCurrent(
          data.docs.map((setDoc) => ({ ...setDoc.data(), id: setDoc.id }))
        );
      }
    } else {
      setCurrent(null);
    }
  }

  const [selectedRow, setSelectedRow] = useState("");

  const deleteCustomer = async () => {
    await updateDoc(doc(db, "customers", selectedRow.id), {
      dataState: 0, //deleted
      editDate: Date.now(),
    });
    const newList = customers.filter((item) => item.id !== selectedRow.id);
    setCustomers(newList);
    UseFilter(newList);
    localStorage.setItem("customers", JSON.stringify(newList));
  };

  const changeFilter = () => {
    UseFilter(customers);
  };

  function UseFilter(customerrray) {
    let value = document.getElementById("filter").value;
    if (!value || value === null || value === undefined || value === "") {
      setFilteredCustomers(
        customerrray.filter(
          (item) =>
            item.dataState === null ||
            item.dataState === undefined ||
            item.dataState !== 0
        )
      );
    } else {
      setFilteredCustomers(
        customerrray.filter(
          (item) =>
            (item.dataState === null ||
              item.dataState === undefined ||
              item.dataState !== 0) &&
            ((item.name !== null &&
              item.name !== undefined &&
              item.name.toLowerCase().includes(value.toLowerCase())) ||
              (item.reference !== null &&
                item.reference !== undefined &&
                item.reference.toLowerCase().includes(value.toLowerCase())) ||
              (item.addressCity !== null &&
                item.addressCity !== undefined &&
                item.addressCity.toLowerCase().includes(value.toLowerCase())))
        )
      );
    }
  }

  return (
    <>
      <Navbar />
      <p className="title">{translation("customers")}</p>
      <div className="buttons">
        <input
          id="filter"
          className="filter"
          onChange={changeFilter}
          type="text"
          placeholder={translation("search")}
          aria-label="Search Input"
        />
         <button className="tableconf" onClick={() => setTableConf(!isTableConf)}>
          {" "}
          <ConfigurationSvg className="confSvg" title={translation("TitleTableConf")} alt="TitleTableConf" />{" "}
        </button>
        <button className="open" onClick={() => openNewCustomer(false)}>
          {" "}
          <OpenSvg title={translation("open")} alt="Open" />{" "}
        </button>
        <button className="new" onClick={() => openNewCustomer(true)}>
          {" "}
          <NewSvg title={translation("new")} alt="New" />{" "}
        </button>
        <button className="delete" onClick={deleteCustomer}>
          {" "}
          <DeleteSvg title={translation("delete")} alt="Delete" />
        </button>
      </div>
      <ReactDimmer
        isOpen={isNewCustomer}
        exitDimmer={setNewCustomer}
        zIndex={103}
        blur={1.5}
      />
       <ReactDimmer
        isOpen={isTableConf}
        exitDimmer={setTableConf}
        zIndex={103}
        blur={1.5}
      />
      <BaseDataTable
        columns={columns}
        data={filteredCustomers}
        onSelect={setSelectedRow}
        currentRow={selectedRow}
        rowDoubleClicked={openNewCustomer}  
      />
      {isNewCustomer && <NewCustomerMenu
        key={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        current={current}
        company={company}
        isNewCustomer={isNewCustomer}
        isNewCustomerDataSet={isNewCustomerDataSet}
        settings={settings}
        customerId={
          current !== null && current !== undefined && current.length > 0
            ? current[0]["id"]
            : null
        }
        closeFunction={setNewCustomer}
      />}
      {isTableConf && <TableConfiguration
        key={settings}
        user={user}
        isTableConf={isTableConf}
        tableConfFields={tableConfFields}
        dbTable='customerTable'
      />}
      <Footer />
    </>
  );
}
