import React, { useRef, useState } from "react";
import "./TableConfiguration.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export const TableConfiguration = ({
  isTableConf,
  isNewCustomerDataSet,
  tableConfFields,
  dbTable, 
  user
}) => {

  const [additionalProperties, setAdditionalProperties] = useState([]);
  const [additionalPropertiesLoaded, setAdditionalPropertiesLoaded] = useState(false);

  async function create(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "users", user.id), getUpdateTable());
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  function getUpdateTable() {
    console.log(additionalProperties);
    if(dbTable === 'customerTable') {
      return {
        customerTable: additionalProperties,
      };
    } else {
      return {
        errorTableConf: {
          message: dbTable + ' not configured!'
        },
      };
    }
  }

  function getValue(column, index) {
    if(index === 0 && additionalPropertiesLoaded) {
      setAdditionalPropertiesLoaded(true)
      initAdditionalProperties(user[dbTable]);
    }

    if (user !== null && user !== undefined 
      && user[dbTable] !== null && user[dbTable] !== undefined) {
        for (let i = 0; i < user[dbTable].length; i++) {
          if(column.equals(user[dbTable][i])) {
            return true;
          }
        }
    }
    return false;
  }

  function initAdditionalProperties(currentProperties) {
    if (
      currentProperties !== null &&
      currentProperties !== undefined &&
      currentProperties.length > 0
    ) {
      setAdditionalProperties(currentProperties);
    } else {
      setAdditionalProperties([{}]);
    }
  }

  const handleFormChangeAdditionalProperties = (index, event) => {
    let data = [...additionalProperties];
    data[index] = event.target.name;
    setAdditionalProperties(data);
  };

  return (
    <div className={`customer-menu ${isTableConf ? "customer-open" : ""}`}>
      <form className="settingForm" onSubmit={create}>
        {/* ADDITIONAL PROPERTIES */}
        <h2 className="settingsSectionTitle">
          {translation("TitleTableConf")}
        </h2>
        {tableConfFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <div className="settingsInputFieldGroupTableConf">
                  <FormControlLabel
                    className="formControlDefault"
                    control={
                      <Checkbox
                        name={input.title}
                        style={{ color: "#ffffff" }}
                        className="checkboxDefault"
                        defaultChecked={getValue(input.title, index)}
                        onChange={(event) =>
                          handleFormChangeAdditionalProperties(index, event)
                        }
                      />
                    }
                    label={input.title}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewCustomerDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
