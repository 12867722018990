import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/auth/Login";
import Dashboard from "./Components/Dashboard";
import PrivateRoute from "./Components/core/PrivateRoute";
import ForgotPassword from "./Components/auth/ForgotPassword";
import { AuthProvider } from "./contexts/AuthContext";
import Signup from "./Components/auth/Signup";
import Products from "./Components/Products";
import Timeplanning from "./Components/Timeplanning";
import Usermanagement from "./Components/Usermanagement";
import Invoices from "./Components/Invoices";
import Customermanagement from "./Components/Customermanagement";
import { SettingsMenu } from "./Components/SettingsMenu";
import Landingpage from "./Landingpage/Landingpage";

function App() {
  return (
    <div className="fullApp">
        <Router>
          <AuthProvider>
            <Routes>
              <Route exact path="/" element={<Login />} />

              <Route exact path="/app/" element={<PrivateRoute />}>
                <Route exact path="/app/" element={<Dashboard />} />
              </Route>
              <Route
                exact
                path="/app/customermanagement"
                element={<PrivateRoute />}
              >
                <Route
                  exact
                  path="/app/customermanagement"
                  element={<Customermanagement />}
                />
              </Route>
              <Route exact path="/app/documents" element={<PrivateRoute />}>
                <Route exact path="/app/documents" element={<Invoices />} />
              </Route>
              <Route exact path="/app/products" element={<PrivateRoute />}>
                <Route exact path="/app/products" element={<Products />} />
              </Route>
              <Route exact path="/app/timeplanning" element={<PrivateRoute />}>
                <Route exact path="/app/timeplanning" element={<Timeplanning />} />
              </Route>
              <Route exact path="/app/usermanagement" element={<PrivateRoute />}>
                <Route exact path="/app/usermanagement" element={<Usermanagement />} />
              </Route>
              <Route exact path="/app/settings" element={<PrivateRoute />}>
                <Route exact path="/app/settings" element={<SettingsMenu />} />
              </Route>
              <Route exact path="/app/signup" element={<Signup />} />
              <Route exact path="/app/login" element={<Login />} />
              <Route
                exact
                path="/app/forgot-password"
                element={<ForgotPassword />}
              />
            </Routes>
          </AuthProvider>
        </Router>
    </div>
  );
}

export default App;
