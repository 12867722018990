import React, { useRef, useState } from "react";
import "./NewProductGroup.css";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import toast from "react-hot-toast";
import "react-dropdown/style.css";
import { ReactComponent as DeleteSvg } from "../../icons/delete.svg";
import BaseDataTable from "./../core/BaseDataTable";

export const NewProductGroup = ({
  isNewProductGroup,
  company,
  isNewProductGroupDataSet,
  current,
  companyId,
  closeFunction,
  productColumns,
  filteredProducts,

}) => {
  const productGroupRef = useRef();

  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [positionFields, setPositionFields] = useState([
    {
      positionId:"",
      positionName: "",
      positionQuantity: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState("");

  async function create(e) {
    e.preventDefault();

    console.log(company);

    try {
      await addDoc(collection(db, "productGroups"), {
          productGroupName: productGroupRef.current.value,
          positions: positionFields,
          newDate: serverTimestamp(),
          editDate: serverTimestamp(),
          companyId: company,
          dataState: 1,
      });

      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "productGroups", current.id), {
        productGroupName: productGroupRef.current.value,
        positions: positionFields,
        editDate: serverTimestamp(),
      });

      toast.success(translation("saveSuccess"));
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(translation("saveError"));
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined) {
      let positionFieldsSet = false;
      console.log(isDefaultSet);
      if (
        column === "productGroupName" &&
        current["positions"] !== null &&
        current["positions"] !== undefined &&
        current["positions"].length > 0 &&
        isDefaultSet !== true
      ) {
        positionFieldsSet = true;
        setPositionFields(current["positions"]);
      } else if (
        column === "productGroupName" &&
        isDefaultSet !== true &&
        positionFieldsSet === false
      ) {
        //set input fields from default
        setPositionFields([
          {
            positionId:"",
            positionName: "",
            positionQuantity: ""
          },
        ]);
      }

      if(isDefaultSet !== true) {
        setIsDefaultSet(true);
      }

      try {
        return current[column];
      } catch {}
    }
    return "";
  }

  const handleFormChange = (index, event) => {
    let data = [...positionFields];
    data[index][event.target.name] = event.target.value;
    setPositionFields(data);
  };

  function addAddressFields() {
    let newfield = {
      positionId: selectedRow.id,
      positionName: selectedRow.productName,
      positionQuantity: 1,
    };

    setPositionFields([...positionFields, newfield]);
  };

  const removePositionFields = (e, index) => {
    e.preventDefault();

    let data = [...positionFields];
    data.splice(index, 1);
    setPositionFields(data);
  };

  return (
    <div className={`newproductgroup-menu ${isNewProductGroup ? "newproductgroup-open" : ""}`}>
      
      <form
        className="settingForm"
        onSubmit={isNewProductGroupDataSet ? create : update}
      >
        <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
        <div className="settingsInputGroup">
            <div className="settingsInputFieldGroup">
                <label className="settinglabel">{translation("titleProductGroupName")}</label>
                    <input
                        className="settingInput"
                        ref={productGroupRef}
                        required
                        autoComplete="off"
                        defaultValue={getValue("productGroupName")}
                    />
            </div>
        </div>

        {/* POSITIONEN */}
        <h2 className="settingsSectionTitle">{translation("Positionen")} </h2>
        {positionFields.map((input, index) => {
          return (
            <div key={index}>
              <div className="settingsInputGroup">
                <button
                  className="remove-button"
                  onClick={(e) => removePositionFields(e, index)}
                >
                  <DeleteSvg
                    title={translation("RemoveAddressToolTip")}
                  ></DeleteSvg>
                </button>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titleDescription")}
                  </label>
                  <input
                    name="positionName"
                    className="settingInputSmall"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.positionName}
                  />
                </div>
                <div className="settingsSmallInputFieldGroup">
                  <label className="settinglabelSmall">
                    {translation("titleQuantity")}
                  </label>
                  <input
                    name="positionQuantity"
                    className="settingInputSmall"
                    autoComplete="off"
                    onChange={(event) => handleFormChange(index, event)}
                    value={input.positionQuantity}
                  />
                </div>
              </div>
            </div>
          );
        })}

        <BaseDataTable
          columns={productColumns}
          data={filteredProducts}
          onSelect={setSelectedRow}
          currentRow={selectedRow}
          rowDoubleClicked={addAddressFields}
        />

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewProductGroupDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
