import React, { useRef, useState } from "react";
import "./NewUserMenu.css";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import translation from "../../language/TranslationProvider";
import "react-dropdown/style.css";

export const NewUserMenu = ({
  isNewUser,
  company,
  isNewUserDataSet,
  userId,
  current,
  settings,
  closeFunction
}) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const userNameRef = useRef();
  const emailRef = useRef();

  async function create(e) {
    e.preventDefault();

    try {
      await addDoc(collection(db, "users"), {
        companyId: company,

        firstname: firstNameRef.current.value,
        lastname: lastNameRef.current.value,
        username: userNameRef.current.value,
        email: emailRef.current.value,

        newDate: Date.now(),
        editDate: Date.now(),
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  async function update(e) {
    e.preventDefault();

    try {
      await updateDoc(doc(db, "users", userId), {
        firstname: firstNameRef.current.value,
        lastname: lastNameRef.current.value,
        username: userNameRef.current.value,
        email: emailRef.current.value,

        editDate: Date.now(),
      });

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  function getValue(column) {
    if (current !== null && current !== undefined && current.length > 0) {

      try {
        return current[0][column];
      } catch {}
    }
    return "";
  }

  return (
    <div className={`user-menu ${isNewUser ? "user-open" : ""}`}>
      <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
      <form
        className="settingForm"
        onSubmit={isNewUserDataSet ? create : update}
      >
        {/* USER */}
        <h2 className="settingsSectionTitle">{translation("User")} </h2>
        <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleFirstName")}
            </label>
            <input
              className="settingInput"
              ref={firstNameRef}
              required
              autoComplete="off"
              defaultValue={getValue("firstname")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleLastName")}
            </label>
            <input
              className="settingInput"
              ref={lastNameRef}
              autoComplete="off"
              defaultValue={getValue("lastname")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleUserName")}
            </label>
            <input
              className="settingInput"
              ref={userNameRef}
              autoComplete="off"
              defaultValue={getValue("username")}
            />
          </div>
          <div className="settingsInputFieldGroup">
            <label className="settinglabel">
              {translation("titleEmail")}
            </label>
            <input
              className="settingInput"
              ref={emailRef}
              disabled
              autoComplete="off"
              defaultValue={getValue("email")}
            />
          </div>
        </div>

        <input
          className="settingSubmit"
          type="submit"
          value={translation(isNewUserDataSet ? "create" : "update")}
        />
      </form>
    </div>
  );
};
