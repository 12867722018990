import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import "./BaseDataTable.css";
import translation from "../../language/TranslationProvider";
import { COLOR } from './Color.js'

const paginationComponentOptions = {
  rowsPerPageText: translation("titleRowsPage"),
  rangeSeparatorText: translation("titleFrom"),
  selectAllRowsItem: true,
  selectAllRowsItemText: translation("titleAll"),
};

function BaseDataTable(props) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateState = useCallback((state) => handleRowClick(state));

  const conditionalRowStyles = [
    {
      when: (row) => row.isSelected,
      style: { backgroundColor: "#6b778d" },
    },
    {
      when: (row) => !row.isSelected,
      style: { backgroundColor: COLOR.main_dark },
    },
  ];

  function handleRowClick(selectedRow) {
    console.log("row clicked");

    if (`event.type === 'contextmenu'`) {
      if (props.currentRow) {
        props.currentRow.isSelected = false;
      }
      selectedRow.isSelected = true;
      props.onSelect(selectedRow);
    }
  }

  return (
    <>
      <div className="tablediv">
        <DataTable
          className="datatable"
          columns={props.columns}
          data={props.data}
          direction="auto"
          //        expandOnRowDoubleClicked
          //        expandableRows
          //        expandableRowsHideExpander
          highlightOnHover
          pagination
          paginationComponentOptions={paginationComponentOptions}
          dense
          responsive
          paginationPerPage={20}
          conditionalRowStyles={conditionalRowStyles}
          onRowClicked={updateState}
          onRowDoubleClicked={() => props.rowDoubleClicked(false)}
        />
      </div>
    </>
  );
}

export default BaseDataTable;
