import React, { useEffect, useState } from "react";
import "./NewProductGroupMenu.css";
import translation from "../../language/TranslationProvider";
import { ReactDimmer } from "react-dimmer";
import { NewProductGroup } from "./NewProductGroup.js";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";
import BaseDataTable from "../core/BaseDataTable";
import { ReactComponent as NewSvg } from "../../icons/new.svg";
import { ReactComponent as OpenSvg } from "../../icons/open.svg";

const columns = [
  {
    name: translation("titleProductGroupName"),
    selector: (row) => row.productGroupName,
    sortable: true,
  },
  {
    name: translation("titlePositionCount"),
    selector: (row) => row.positions.length,
    sortable: true,
  }
];

export const NewProductGroupMenu = ({
  isProductGroup,
  company,
  current,
  closeFunction,
  productColumns,
  filteredProducts
}) => {

  const [selectedProjectRow, setSelectedProjectRow] = useState("");
  const [isNewProductGroup, setNewProductGroup] = useState(false);
  const [isNewProductGroupDataSet, setNewProductGroupDataSet] = useState(false);
  const [filteredProductGroups, setFilteredProductGroups] = useState([]);

  useEffect(() => {
    const getProductGroups = async () => {
        let companyId = company;

        const productGroupCollection = query(
          collection(db, "productGroups"),
          where("companyId", "==", companyId)
        );
        const data = await getDocs(productGroupCollection);
        let productGroupList = data.docs.map((productGroupDoc) => ({
          ...productGroupDoc.data(),
          id: productGroupDoc.id,
        }));
        setFilteredProductGroups(productGroupList);
    };
    getProductGroups();
  }, [company, closeFunction]);

  async function openNewProductGroup(isNewDataSet) {
    setNewProductGroup((prevState) => !prevState);
    setNewProductGroupDataSet(isNewDataSet);
  }

  return (
    <>
    <div className={`productgroup-menu ${isProductGroup ? "productgroup-open" : ""}`}>
    <button className="closeForm" onClick={() => closeFunction(false)}>X</button>
        <div
          className="settingForm"
        >
          <div className="settingsInputGroup">
          <div className="settingsInputFieldGroup">
          <div className="buttons">
          <input
          //just for format of other buttons
          id="filter"
          className="filter invisible"
          //onChange={changeFilter}
          type="text"
          placeholder={translation("search")}
          aria-label="Search Input"
          />
            <button 
              className="open" 
              onClick={() => openNewProductGroup(false)}
              >
              {" "}
              <OpenSvg title={translation("open")} alt="Open" />{" "}
            </button>
            <button 
              className="new" 
              onClick={() => openNewProductGroup(true)}
              >
              {" "}
              <NewSvg title={translation("new")} alt="New" />{" "}
            </button>
          </div>
          <BaseDataTable
            columns={columns}
            data={filteredProductGroups}
            onSelect={setSelectedProjectRow}
            currentRow={selectedProjectRow}
            rowDoubleClicked={openNewProductGroup}  
          />
          </div>
          </div>
        </div>

      <ReactDimmer
        isOpen={isNewProductGroup}
        exitDimmer={setNewProductGroup}
        zIndex={104}
        blur={1.5}
      />

      {isNewProductGroup === true && (
        <NewProductGroup
          key={null}
          isNewProductGroup={isNewProductGroup}
          company={company}
          current={selectedProjectRow}
          isNewProductGroupDataSet={isNewProductGroupDataSet}
          closeFunction={setNewProductGroup}
          productColumns={productColumns}
          filteredProducts={filteredProducts}
        />
      )}
    </div>
    </>
  );
};
