import React from "react";
import Navbar from "./core/Navbar";
import Footer from "./core/Footer";
import "./Dashboard.css";
import translation from "../language/TranslationProvider";

export default function Dashboard() {
  return (
    <>
      <Navbar />
      <Footer />
    </>
  );
}
